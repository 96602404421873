import * as React from 'react'
import { UserYes } from '../types';
const styles = require('./styles/styles.scss')
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import axios from 'axios'
import Store from '../store/store'
import PieStats from '../dashboard/PieStats'
import Grid from '@material-ui/core/Grid';
import { IDBWfTask } from '../Workflow/interfaces/IWorkflow';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

let TodayDate = new Date
let tempArray2 = []
let phrase = [
  "vous êtes très en beauté aujourd'hui !",
  'vous semblez livide, prenez ce jour de congé offert par le patron.',
  'on dit de vous que vous êtes sympatique.',
  'qu\'attendez vous pour proposer un verre à Jaqueline de la compta ?',
  'ne mettez pas vos coudes sur la table.',
  "ne mettez pas votre doigt dans votre nez, tout le monde vous regarde...",
  'Régis à dit que vous aviez pris des hanches ...',
  'dites bonjour à votre patron qui regarde en ce moment même par dessus votre épaule !',
  'faites coucou au dev qui vous regarde via la webcam :)']
let random = []


interface State {
  period: number, // nombre de documents traités en fonction l’utilisateur connecté
  nbDocumentTraiteByUserConnected: number, // nombre de documents traités en fonction l’utilisateur connecté
  startDate?: Date;
  endDate?: Date;
  nbDossierTermine?: number,
  nbDossierEncours?: number
  nbOrientation?: number
  nameOfOrientation?: string[]
  tempNameOfOrientation?: string[]
  nbOfNameOrientation?: number[]
  taskByUserConnected?: any[]
  allStepIdFromIdTask?: any[]
  IdDocByUserConnected?: any[]
  nbDossierEnRetard?: number
  nbDossierATemps?: number
  // TASKS ////
  reload?: boolean,
  stateProject?: string,
  dateStartProject?: boolean,
  enPreparation?: boolean,
  enCours?: boolean,
  terminado?: boolean,
  refuse?: boolean,
  differe?:boolean,
  sortTasks: IDBWfTask[],
  unselectedRows?: number[];
  allTasks: IDBWfTask[];
  stateMessageTab: string;
  // END TASKS ////
  // MESSAGES ////
  myMessagesSend: any[];
  allMessage:any[];
  checkBoxEncours: boolean;
  checkBoxTerminée: boolean;
  checkBoxEnAttente: boolean;
  checkBoxRefuse: boolean;
  checkBoxDiffere:boolean;
  messageForMe: any[];
  ongletSelect: number;
  messageToDisplay: any[];
  // END MESSAGES ////
}

const Window: any = window;

@observer
class Dashboard extends React.Component<RouteComponentProps<any>, State>{

  public constructor(props: any) {
    super(props)
    const today = new Date();
    const last2mounth = new Date(today.getFullYear(), today.getMonth() - 1, 0); // pour régler le date picker a 2 mois en arrière , à modifier au besoin
    last2mounth.setDate(Math.min(today.getDate(), last2mounth.getDate()));

    this.state = {
      period: undefined, // nombre de documents traités en fonction l’utilisateur connecté
      nbDocumentTraiteByUserConnected: undefined, // nombre de documents traités en fonction l’utilisateur connecté
      startDate: last2mounth,
      endDate: new Date(),
      nbDossierEncours: undefined,
      nbDossierTermine: undefined,
      nameOfOrientation: [],
      nbOrientation: undefined,
      taskByUserConnected: [],
      allStepIdFromIdTask: [],
      IdDocByUserConnected: [],
      nbDossierEnRetard: undefined,
      nbDossierATemps: undefined,
      nbOfNameOrientation: [],
      tempNameOfOrientation: [],
      ///TASKS///
      reload: false,
      stateProject: "",
      enPreparation: Window.MY_TASKS_CONFIG.find(x => x == "En Préparation") != undefined ? true : false,
      enCours: Window.MY_TASKS_CONFIG.find(x => x == "En Cours") != undefined ? true : false,
      terminado: Window.MY_TASKS_CONFIG.find(x => x == "Terminé") != undefined ? true : false,
      refuse: Window.MY_TASKS_CONFIG.find(x => x == "Refusé") != undefined ? true : false,
      differe: Window.MY_TASKS_CONFIG.find(x => x == "Différée") != undefined ? true : false,
      allTasks: [],
      sortTasks: [],
      unselectedRows: [],
      stateMessageTab: "Récupération des tâches en cours...",
      /// END TASKS ////
      /// MESSAGES ////
      checkBoxEncours: true,
      checkBoxTerminée: false,
      checkBoxEnAttente: true,
      checkBoxRefuse: false,
      checkBoxDiffere:false,
      messageForMe: [],
      myMessagesSend: [],
      allMessage:[],
      ongletSelect: 0,
      messageToDisplay: []
      /// END MESSAGES ////
    }
  }

  componentDidMount() {
    this.getAllTasksByUser();
    // this.randomizePhrase();
    this.getTaskPostItByUser();
  }

  onClickButton = () => {
    Store.snackBar.open = true;
    Store.snackBar.message = "Merci d'avoir pris le temps d'évaluer notre produit. C'est gentil.";
  }

  getStepIdFromIdTask() { // Permet de récupérer les step en rapport avec les tâches , ainsi que de compter le nombre de step d'orientation
    let tempArray = []
    this.state.taskByUserConnected.forEach(task => {
      tempArray.push(task.stepId)
    });
    let uniqueSet = Array.from(new Set(tempArray)) // POUR VIRER LES DOUBLONS DU TABLEAU
    axios.get(Store.wsPath + '/1/documents/getStepIdFromIdTask', {
      params: {
        stepId: uniqueSet,
        startDate: this.state.startDate,
        endDate: this.state.endDate
      }
    })
      .then(response => {
        this.setState({ nbOrientation: response.data.getProjectIdIsStepOrientation.length })
        response.data.getProjectIdIsStepOrientation.forEach(element => {
          this.pushProjectName(element)
        });
        this.setState({ nameOfOrientation: tempArray2 })
        this.counterProjectSameName(this.state.nameOfOrientation)
        this.setState({ allStepIdFromIdTask: response.data.getProject })
        this.getIdDocFromProject()
      })
  }

  getIdDocFromProject() { // Pour récupérer les idxDoc et compter le nombre de Step en cours et Terminé et les noms de projets
    let tempArray = []
    this.state.allStepIdFromIdTask.forEach(step => { // Pour virer les doublons du tableau d'id
      tempArray.push(step.projetId)
    });
    let uniqueSet = Array.from(new Set(tempArray))
    axios.get(Store.wsPath + '/1/documents/getIdDocFromProject', {
      params: {
        idProject: uniqueSet,
        startDate: this.state.startDate,
        endDate: this.state.endDate
      }
    })
      .then(response => {
        this.setState({
          nbDossierEncours: response.data.getIdDocEncours.length,
          nbDossierTermine: response.data.getIdDocTerminé.length,
          IdDocByUserConnected: response.data.getIdDoc
        })
        this.getDocumentbyIdDoc()
        this.getRatioDossierEnRetard(response)
      })
  }

  getDocumentbyIdDoc() { // Pour récupérer les documents traités
    let tempArray = []
    this.state.IdDocByUserConnected.forEach(idDoc => {
      tempArray.push(idDoc.id)
    });
    let uniqueSet = Array.from(new Set(tempArray))
    this.setState({ nbDocumentTraiteByUserConnected: uniqueSet.length })
  }

  getRatioDossierEnRetard = (response) => { // Pour calculer le ratio de dossiers traités à temps et en retard pour l'utilisateur connecté
    let tempCountEnRetard = 0
    response.data.getIdDoc.forEach(project => {
      if (project.plannedDateEnd > TodayDate) {
        tempCountEnRetard = tempCountEnRetard + 1
      }
    });
    this.setState({ nbDossierEnRetard: tempCountEnRetard }) // Nb de dossiers en retards
    let tempLenght = response.data.getIdDoc.length // Nb de dossiers total de la réponse
    let tempCountATemps = tempLenght - tempCountEnRetard // Soustraction du nb de dossiers en retard au nb de dossiers
    this.setState({ nbDossierATemps: tempCountATemps }) // Pour trouver le nb  de dossiers traités à temps
  }

  pushProjectName(element) {
    this.setState({ tempNameOfOrientation: element.name })
    tempArray2.push(this.state.tempNameOfOrientation)
  }

  counterProjectSameName = (array) => {
    let counter = []
    let label = []

    array.forEach(orientationName => {
      const find = label.indexOf(orientationName);
      if (find == -1) {
        counter.push(1)
        label.push(orientationName)
      }
      else {
        counter[find] = counter[find] + 1;
      }
    })
    this.setState({ nameOfOrientation: label })
    this.setState({ nbOfNameOrientation: counter })
  }
  onChangeDatePicker = name => event => {
    if (name == 'startDate') {
      this.setState({ startDate: event })
    }
    else if (name == 'endDate') {
      this.setState({ endDate: event })
    }
  }
  randomizePhrase() {
    let tempArray = phrase.slice(0)
    while (random.length < 1) {
      random.push(tempArray.splice(Math.floor(Math.random() * tempArray.length), 1)[0])
    }
  }

  ////// TASKS //////////////////////////////////////////////////////////////////////
  public convertDateFromDataBaseToDisplaying = (cell, row: IDBWfTask) => {
    let dateFormated: string = "";
    dateFormated = new Date(cell).toLocaleDateString();


    return dateFormated;
  }
  public convertEstimateDateFromDataBaseToDisplaying = (cell, row: IDBWfTask) => {

    const dateEnd: Date = new Date(row.WfStep.WfProject.dateFinSouhaitee);
    try {
      const dateFormated = dateEnd.toLocaleDateString();
      return dateFormated;
    } catch (error) {
      const dateFormated = "Pas de date";
      return dateFormated;
    }
  }
  public displayDocumentName = (cell, row: IDBWfTask) => {
    const style:React.CSSProperties = { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" };
    // Si la tâche n'a pas été vu par l'utilisateur on la mets en gras
    if(row.task_view == false) {
      style["fontWeight"] = "bold";
    }
    let link = "/document?w=" + row.WfStep.WfProject.id + "&s=" + row.WfStep.id + "&t=" + row.id;
    return <a href={link} target="_self" style={style}>{row.WfStep.WfProject.name}</a>
  }

  public displayProjectName = (cell:string, row: any) => {
    const style:React.CSSProperties = { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" };
    let link = "/document?w=" + row.idProject + "&s=" + row.stepId + "&t=" + row.taskId;
    return <a href={link} target="_self" style={style}>{cell}</a>
  }

  public getAllTasksByUser = () => {
    this.setState({ stateMessageTab: "Récupération des tâches en cours..." }, () => {
      axios.get(Store.wsPath + '/1/workflows/getAllTasksAndInfoByUserConnected', {
        params: {
          idUser: Store.userConnected.id
        }
      }).then((responseTask) => {
        const allTask: IDBWfTask[] = responseTask.data;
        const allTaskWithCircuitName = allTask.map(tas => {
          const task = tas;
          const circuitName = tas.WfStep.WfProject.name;
          task["circuitName"] = circuitName;
          task["circuitStatut"] = tas.WfStep.WfProject.state;
          return task;
        });
        let message: string = "Liste des tâches";
        if (responseTask.data.length == 0) {
          message = "Aucune tâche à afficher";
        }
        this.setState({ allTasks: allTaskWithCircuitName, stateMessageTab: message }, () => {
          this.trierTasks();
        });
      }).catch(error => {
        console.error("Error 'getAllTasksByUser' : ", error);
        this.setState({ stateMessageTab: "Une erreur est survenue lors de la récupération des tâches" });
      });
    });
  }

  public projectStatutDisplaying = (cell, row: IDBWfTask) => {
    return row.WfStep.WfProject.state
  }
  public isDiffered = (cell, row: IDBWfTask) => {
    return row.isDiffered == true ? "Oui": "Non"
  }

  public trierTasks = () => {
    const tempStore = this.state.allTasks;
    const tempSortTasks = [];
    if (this.state.enPreparation) {
      tempStore.forEach(task => {
        if (task.state == "En préparation" && task.isDiffered == false) {
          tempSortTasks.push(task);
        }
      });
    }
    if (this.state.enCours) {
      tempStore.forEach(task => {
        if (task.state == "En cours" && task.isDiffered == false) {
          tempSortTasks.push(task);
          Store.countTaskUser = tempStore.filter(task => task.state == "En cours").length;
        }
      });
    }
    if (this.state.terminado) {
      tempStore.forEach(task => {
        if (task.state == "Terminée" && task.isDiffered == false) {
          tempSortTasks.push(task);
        }
      });
    }
    if (this.state.refuse) {
      tempStore.forEach(task => {
        if ((task.state == "Refusé" || task.state == "Annulé") && task.isDiffered == false) {
          tempSortTasks.push(task);
        }
      });
    }
    if (this.state.differe) {
      tempStore.forEach(task => {
        if (task.isDiffered == true && task.state == "En cours") {
          tempSortTasks.push(task);
        }
      });
    }
    this.setState({ sortTasks: tempSortTasks });
  }

  public onChange = (name: string) => event => {
    // this.getAllTasksByUser()
    if (name == "enPreparation") {
      this.setState({
        enPreparation: event.target.checked
      }, () => { this.trierTasks() })

    } else if (name == "enCours") {
      this.setState({
        enCours: event.target.checked
      }, () => { this.trierTasks() })
    } else if (name == "terminado") {
      this.setState({
        terminado: event.target.checked
      }, () => { this.trierTasks() })
    } else if (name == "refuse") {
      this.setState({
        refuse: event.target.checked
      }, () => { this.trierTasks() })
    }
    else if (name == "differe") {
      this.setState({
        differe: event.target.checked
      }, () => { this.trierTasks() })
    }
  }

  public viewTask = (row) => {
    let styleRowUnread = {}
    if (row != undefined && row != null) {
      if ((row.taskview != undefined && row.taskview != null) && (row.taskview == false)) {
        styleRowUnread = { fontWeight: 'bold' }
      }
    }
    return styleRowUnread
  }
  sortTitle(a, b, order) {
    if (order === 'desc') {
      if (a.WfStep.WfProject.Document.title > b.WfStep.WfProject.Document.title) {
        return -1;
      }
      else if (a.WfStep.WfProject.Document.title < b.WfStep.WfProject.Document.title) {
        return 1;
      }
      else
        return 0;
    } else {
      if (a.WfStep.WfProject.Document.title < b.WfStep.WfProject.Document.title) {
        return -1;
      }
      else if (a.WfStep.WfProject.Document.title > b.WfStep.WfProject.Document.title) {
        return 1;
      }
      else
        return 0;
    }
  }
  sortProjectStatut(a, b, order) {
    if (order === 'desc') {
      if (a.WfStep.WfProject.state > b.WfStep.WfProject.state) {
        return -1;
      }
      else if (a.WfStep.WfProject.state < b.WfStep.WfProject.state) {
        return 1;
      }
      else
        return 0;
    } else {
      if (a.WfStep.WfProject.state < b.WfStep.WfProject.state) {
        return -1;
      }
      else if (a.WfStep.WfProject.state > b.WfStep.WfProject.state) {
        return 1;
      }
      else
        return 0;
    }
  }
  sortDateSouhaite(a, b, order) {
    const dateEndA: Date = new Date(a.WfStep.WfProject.dateFinSouhaitee);
    const dateEndB: Date = new Date(b.WfStep.WfProject.dateFinSouhaitee);
    const timestampA = dateEndA.getTime();
    const timeStampB = dateEndB.getTime();
    if (order === 'desc') {
      if (timestampA > timeStampB) {
        return -1;
      }
      else if (timestampA < timeStampB) {
        return 1;
      }
      else
        return 0;
    } else {
      if (timestampA < timeStampB) {
        return -1;
      }
      else if (timestampA > timeStampB) {
        return 1;
      }
      else
        return 0;
    }
  }
  sortDateDone(a, b, order) {
    const dateEndA: Date = new Date(a.dateDone);
    const dateEndB: Date = new Date(b.dateDone);
    const timestampA = dateEndA.getTime();
    const timeStampB = dateEndB.getTime();
    if (order === 'desc') {
      if (timestampA > timeStampB) {
        return -1;
      }
      else if (timestampA < timeStampB) {
        return 1;
      }
      else
        return 0;
    } else {
      if (timestampA < timeStampB) {
        return -1;
      }
      else if (timestampA > timeStampB) {
        return 1;
      }
      else
        return 0;
    }
  }
  getCaret(direction) {
    if (direction === 'asc') {
      return (<Icon style={{ fontSize: '26px' }} >arrow_drop_up</Icon>);
    }
    if (direction === 'desc') {
      return (<Icon style={{ fontSize: '26px' }} >arrow_drop_down</Icon>);
    }
    return (<span></span>);
  }

  ///// END TASKS //////

  ///// MESSAGES ///////
  getTaskPostItByUser() {
    axios.get(Store.wsPath + '/1/postIt/getTaskPostItByUser', {
      params: {
        idUserConnected: Store.userConnected.id
      }
    }).then(async response => {
      this.toSetDataForTable(response.data);
    });
  }
  goToWorkFlow = (row) => {
    this.props.history.push('/document?w=' + row.idProject + "&s=" + row.stepId + "&t=" + row.taskId);
  }
  toSetDataForTable(allPostItBlue: { myMessage: any[], forMe: any[] }) {
    const allMyMessage: any[] = [];
    const allForMeMessage: any[] = [];
    const allMessage:any[] = [];

    if (allPostItBlue.forMe != undefined && allPostItBlue.forMe != null && allPostItBlue.forMe.length > 0) {
      for (let i = 0; i < allPostItBlue.forMe.length; i++) {
        if (allPostItBlue.forMe[i].TaskPostIts.length > 0) {
          for (let j = 0; j < allPostItBlue.forMe[i].TaskPostIts.length; j++) {
            allPostItBlue.forMe[i].TaskPostIts[j].PostItMessages.forEach(message => {
              // A modifier en mode sherPoint
              const tempObject = {
                id: message.id,
                date: message.createdAt,
                message: message.message,
                idUserQuiAEcritLeMessage: message.idxUser,
                idDestinataire: allPostItBlue.forMe[i].userIdx,
                idProject: allPostItBlue.forMe[i].WfStep.WfProject.id,
                stateStep: allPostItBlue.forMe[i].WfStep.state,
                designationProjet: allPostItBlue.forMe[i].WfStep.WfProject.name,
                idPostIt: allPostItBlue.forMe[i].TaskPostIts[j].id,
                taskName: allPostItBlue.forMe[i].name,
                stateTask: allPostItBlue.forMe[i].state,
                stepId: allPostItBlue.forMe[i].WfStep.id,
                taskId: allPostItBlue.forMe[i].id
              }
              

              allForMeMessage.push(tempObject);
              allMessage.push(tempObject);
            });
          }
        }
      }
    }

    if (allPostItBlue.myMessage != undefined && allPostItBlue.myMessage != null && allPostItBlue.myMessage.length > 0) {
      for (let i = 0; i < allPostItBlue.myMessage.length; i++) {
        if (allPostItBlue.myMessage[i].TaskPostIts.length > 0) {
          for (let j = 0; j < allPostItBlue.myMessage[i].TaskPostIts.length; j++) {
            allPostItBlue.myMessage[i].TaskPostIts[j].PostItMessages.forEach(message => {
              // A modifier en mode sherPoint
              const tempObject = {
                id: message.id,
                date: message.createdAt,
                message: message.message,
                idUserQuiAEcritLeMessage: message.idxUser,
                idDestinataire: allPostItBlue.myMessage[i].userIdx,
                idProject: allPostItBlue.myMessage[i].WfStep.WfProject.id,
                stateStep: allPostItBlue.myMessage[i].WfStep.state,
                designationProjet: allPostItBlue.myMessage[i].WfStep.WfProject.name,
                idPostIt: allPostItBlue.myMessage[i].TaskPostIts[j].id,
                taskName: allPostItBlue.myMessage[i].name,
                stateTask: allPostItBlue.myMessage[i].state,
                stepId: allPostItBlue.myMessage[i].WfStep.id,
                taskId: allPostItBlue.myMessage[i].id
              }

              allMyMessage.push(tempObject)
              allMessage.push(tempObject);
            });
          }
        }
      }
    }

    this.setState({ myMessagesSend: allMyMessage, messageForMe: allForMeMessage,allMessage:allMessage }, () => this.sortAllMessagesByUsers())
  }

  public convertDate = (date) => {
    const newDate = new Date(date)
    if ((newDate.getMonth() + 1) < 10) {
      if (newDate.getDate() < 10) {
        return "0" + newDate.getDate() + "/0" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
      }
      else {
        return newDate.getDate() + "/0" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
      }
    } else {
      if (newDate.getDate() < 10) {
        return "0" + newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
      }
      else {
        return newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
      }
    }
  }

  public convertUser = (idUser) => {
    if(Store.userConnected.id == idUser){
      return "Vous"
    }else{
      const tempUserFind = Store.allUsers.find(user =>
        user.id ==idUser
      )
      if (tempUserFind != undefined || null) {
        return tempUserFind.firstname + ' ' + tempUserFind.lastname
      }
      else {
        return "Auteur introuvable"
      }
    }
    
  }

  sortAllMessagesByUsers() {
    let tempArrayAllMessages = this.state.allMessage
    const finalTempArrayMessages = []
    
     


    if (this.state.checkBoxEncours) { // En cours
      tempArrayAllMessages.forEach(message => {
        if (message.stateTask == "En cours") {
          finalTempArrayMessages.push(message)
        }
      });
    }
    if (this.state.checkBoxTerminée) { // terminée
      tempArrayAllMessages.forEach(message => {
        if (message.stateTask == "Terminée") {
          finalTempArrayMessages.push(message)
        }
      });
    }
    if (this.state.checkBoxEnAttente) { // en attente
      tempArrayAllMessages.forEach(message => {
        if (message.stateTask == "En préparation") {
          finalTempArrayMessages.push(message)
        }
      });
    }
    if (this.state.checkBoxRefuse) { // refusé
      tempArrayAllMessages.forEach(message => {
        if (message.stateTask == "Refusé" || message.stateTask == "Annulé") {
          finalTempArrayMessages.push(message)
        }
      });
    }
    const TempArray = Array.from(new Set(finalTempArrayMessages));
    this.setState({ messageToDisplay: TempArray });
  }

  buttonFormatTask = status => event => {
    if (status == 'En cours') {
      this.setState({ checkBoxEncours: event.target.checked }, () => this.sortAllMessagesByUsers())
    }
    if (status == 'Terminée') {
      this.setState({ checkBoxTerminée: event.target.checked }, () => this.sortAllMessagesByUsers())
    }
    if (status == 'En attente') {
      this.setState({ checkBoxEnAttente: event.target.checked }, () => this.sortAllMessagesByUsers())
    }
    if (status == 'Refusé') {
      this.setState({ checkBoxRefuse: event.target.checked }, () => this.sortAllMessagesByUsers())
    }
  }

  onChangeOnglet = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ ongletSelect: newValue }, () => this.sortAllMessagesByUsers())
  }

  ///// END MESSAGES ////////
  public render() {
    this.state.reload
    const onClickRow = {
      clickToSelect: true,
      onSelect: this.goToWorkFlow,
      mode: 'radio',
      bgColor: '#10a544',
      hideSelectColumn: true
    }
    const optionsMessages = {
      sortIndicator: true,
      defaultSortName: 'id',
      noDataText: 'Aucun message à afficher',
      sizePerPage: 10,
    };

    const optionsTasks = {
      sortIndicator: true,
      defaultSortName: 'formatDateEstimatedEndTask',
      noDataText: this.state.stateMessageTab,
      sizePerPage: 10,
    };
    return (
      <div className={styles.mainContent} >
        <div>
          <div className={styles.secondContent}>
            <div className={styles.dateContainer} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Mon tableau de bord</h5></div>
            <div>
              <div className={styles.thirdContent} style={{marginTop:"40px"}}>
                <div className={styles.contentCheckbox} style={{ background: Store.themeParam.backgroundPrimary }}>
                  <div>
                    <h5>Mes tâches </h5>
                  </div>
                  <div className={styles.checkDiv}>
                    <FormControlLabel
                      className={styles.checkboxFiltre}
                      control={
                        <Checkbox color="primary" onChange={this.onChange('enPreparation')} checked={this.state.enPreparation} className="checkkkk" />
                      }
                      label="En préparation"
                    />
                    <FormControlLabel
                      className={styles.checkboxFiltre}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.onChange('enCours')}
                          checked={this.state.enCours}
                        />
                      }
                      label="En cours"
                    />
                    <FormControlLabel
                      className={styles.checkboxFiltre}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.onChange('terminado')}
                          checked={this.state.terminado}
                        />
                      }
                      label="Terminée"
                    />
                    <FormControlLabel
                      className={styles.checkboxFiltre}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.onChange('refuse')}
                          checked={this.state.refuse}
                        />
                      }
                      label="Refusé"
                    />
                    <FormControlLabel
                      className={styles.checkboxFiltre}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.onChange('differe')}
                          checked={this.state.differe}
                        />
                      }
                      label="Différée"
                    />
                  </div>
                </div>
                {this.state.sortTasks ?
                  <div style={{ marginLeft: '20px', marginRight: "20px" }}>
                    <BootstrapTable
                      multiColumnSearch
                      data={this.state.sortTasks}
                      searchPlaceholder='Rechercher dans la liste'
                      search={true}
                      options={optionsTasks}
                      className={styles.contentMyTasks}
                      // headerStyle={{ zIndex: 220, position: "relative" }}
                      pagination version='4'
                      // trStyle={this.viewTask}
                      size="sm"
                    >
                      <TableHeaderColumn isKey={true} dataField='id' hidden >Id</TableHeaderColumn>
                      <TableHeaderColumn dataField='circuitName' dataFormat={this.displayDocumentName} dataSort={true} sortFunc={this.sortTitle} caretRender={this.getCaret}>Circuit</TableHeaderColumn>
                      <TableHeaderColumn dataField='circuitStatut' dataAlign='center' headerAlign='center' width="200px" dataFormat={this.projectStatutDisplaying} sortFunc={this.sortProjectStatut} dataSort caretRender={this.getCaret}>Statut circuit</TableHeaderColumn>
                      <TableHeaderColumn dataField='name' dataAlign='center' headerAlign='center' width="120px" dataSort caretRender={this.getCaret}>Tâche</TableHeaderColumn>
                      <TableHeaderColumn dataField='state' dataAlign='center' headerAlign='center' width="120px" dataSort caretRender={this.getCaret}>Statut tâche</TableHeaderColumn>
                      <TableHeaderColumn dataField='state' dataAlign='center' headerAlign='center' width="100px" dataFormat={this.isDiffered} dataSort caretRender={this.getCaret}>Différée</TableHeaderColumn>
                      
                      {/* <TableHeaderColumn dataField='updatedAt' width="120px" dataSort={true} dataFormat={this.convertDateFromDataBaseToDisplaying} >Date Maj</TableHeaderColumn> */}
                      {/* <TableHeaderColumn dataField='dateFinSouhaitee' width="120px" dataFormat={this.convertEstimateDateFromDataBaseToDisplaying} dataSort caretRender={this.getCaret} sortFunc={this.sortDateSouhaite} >Date de fin estimée</TableHeaderColumn> */}
                      {/* <TableHeaderColumn dataField='dateDone' width="120px" dataFormat={this.convertDateFromDataBaseToDisplaying} dataSort caretRender={this.getCaret} sortFunc={this.sortDateDone}>Date de fin réelle</TableHeaderColumn> */}
                      {/* <TableHeaderColumn width='82px' dataField='id' dataFormat={this.toolbox} >Actions</TableHeaderColumn> */}
                    </BootstrapTable>
                  </div>
                  : ""}
              </div>
              <div className={styles.thirdContent} style={{marginTop:"50px"}}>
                <div className={styles.contentCheckbox} style={{ marginBottom: "10px", background: Store.themeParam.backgroundPrimary }}>
                  <h5>Mes messages</h5>
                  <div className={styles.checkDiv}>
                  <FormControlLabel
                      className={styles.checkboxFiltre}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.buttonFormatTask('En attente')}
                          checked={this.state.checkBoxEnAttente}
                        />
                      }
                      label="En Préparation"
                    />
                    <FormControlLabel
                      className={styles.checkboxFiltre}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.buttonFormatTask('En cours')}
                          checked={this.state.checkBoxEncours}
                        />
                      }
                      label="En cours"
                    />
                    <FormControlLabel
                      className={styles.checkboxFiltre}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.buttonFormatTask('Terminée')}
                          checked={this.state.checkBoxTerminée}
                        />
                      }
                      label="Terminée"
                    />
                    <FormControlLabel
                      className={styles.checkboxFiltre}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.buttonFormatTask('Refusé')}
                          checked={this.state.checkBoxRefuse}
                        />
                      }
                      label="Refusé"
                    />
                  </div>
                </div>
                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                  {/* <AppBar position='static'>
                    <Tabs
                      onChange={this.onChangeOnglet}
                      value={this.state.ongletSelect}
                      indicatorColor='primary'
                    >
                      <Tab label="Mes messages adressés" style={{ background: Store.themeParam.backgroundSecondary, marginRight: "10px" }}> </Tab>
                      <Tab label="Mes messages envoyés" style={{ background: Store.themeParam.backgroundSecondary }}> </Tab>
                    </Tabs>
                  </AppBar> */}
                  <BootstrapTable
                    multiColomnSearch
                    data={this.state.messageToDisplay}
                    searchPlaceholder='Rechercher dans la liste'
                    search={true}
                    options={optionsMessages}
                    selectRow={onClickRow}
                    pagination version='4'
                    bordered={true}
                    className={styles.contentMyTasks}
                    trStyle={{ cursor: "pointer" }}
                  >
                    <TableHeaderColumn isKey={true} dataField='id' hidden dataAlign='center' headerAlign='center' >id</TableHeaderColumn>
                    <TableHeaderColumn datasort={true} dataField='designationProjet' dataFormat={this.displayProjectName}>Circuit</TableHeaderColumn>
                    <TableHeaderColumn datasort={true} dataField='taskName' dataAlign='center' headerAlign='center' >Tâche</TableHeaderColumn>
                    <TableHeaderColumn dataField='stateTask' dataAlign='center' headerAlign='center'  dataSort caretRender={this.getCaret}>Statut tâche</TableHeaderColumn>
                    <TableHeaderColumn datasort={true} dataField='idUserQuiAEcritLeMessage' dataFormat={this.convertUser} dataAlign='center' headerAlign='center' >Auteur</TableHeaderColumn>
                    <TableHeaderColumn datasort={true} dataField='idDestinataire' dataFormat={this.convertUser} dataAlign='center' headerAlign='center' >Destinataire</TableHeaderColumn>
                    <TableHeaderColumn datasort={true} dataField='date' dataFormat={this.convertDate} dataAlign='center' headerAlign='center' >Date</TableHeaderColumn>
                    <TableHeaderColumn datasort={true} dataField='message' dataAlign='center' headerAlign='center' >Message</TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Dashboard);