import * as React from 'react'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Icon from '@material-ui/core/Icon'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'
import Store from '../store/store'
const styles = require('./styles/actionWorkflow.scss');
import { observer } from "mobx-react";
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IDBWfTask, ITypeInvitation, IFormModel } from './interfaces/IWorkflow';
import SelectReact, { createFilter } from 'react-select';
const modal = require("../../styles/styles.module.scss");
import HistoricActions from './HistoricActions';
import { DialogFooter, DialogType, Dialog as DialogFabric } from "office-ui-fabric-react/lib/Dialog";
import { Spinner as SpinnerFabric, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import Spinner from "../Spinner/Spinner";
import IconButton from "@material-ui/core/IconButton";
import AppsIcon from "@material-ui/icons/Apps";
import ListIcon from "@material-ui/icons/List";
import TimerOffIcon from '@material-ui/icons/TimerOff';
import TimerIcon from '@material-ui/icons/Timer';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { MenuList, customSingleValue } from './../helper/reactSelectCustomRender'
import { useQuery } from '../helper/helpers';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Delete from '@material-ui/icons/Delete';
import {
    MuiPickersUtilsProvider,
    DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import Checkbox from '@material-ui/core/Checkbox';
import { Checkbox as CheckboxFluent } from 'office-ui-fabric-react/lib/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Separator } from 'office-ui-fabric-react/lib/Separator';

interface IActionWorkflowProps {
    contextualMenuOpen: string;
    myStepTaskSelectionned: IDBWfTask;
}

interface IActionWorkflowState {
    openAction?: string;
    messageMotifRefus?: string;
    messageMotifRefusForm?: string;
    refusGeneralMail?: boolean;
    messageMotifReorientation?: string;
    messageMotifReturn?: string;
    newTaskInserted?: string;
    deleteModal?: boolean;
    suggestionInvitation?: ITypeInvitation[],
    suggestionFormModel?: IFormModel[],
    choiceType?: ITypeInvitation;
    openModalHistoric?: boolean;
    loading: string; // prend le nom de l'action en cours

    idModelForReorientation: number
    model: any
    displayMode: string;
    userConnectedIsInGroup: boolean
    userIdModif: number;
    userAlreadyOnStep: any[];
    createFormModal: boolean;
    formName: string;
    deleteDialogWFParams: {
        hideDialog: boolean;
        loading: boolean;
        error: boolean;
        restartWF: boolean;
        deleteAll: boolean;
    },
    selectedWFToDelete: number;
    convocationModal: {
        open: boolean;
        convocationsFiles: { name: string, idForm?: number, lienSP?: string, id?: number, add?: boolean, buffer?: any }[];
        loading: boolean;
        error: boolean;
        errorMessage: string;
        convocationsFilesAdded: any[];
        fileConvocationName: string;
        fileConvocation: any;
        loadingAddButton: boolean;
        convocationData: {
            convocDate: string;
            convocPlace: string;
            convocLink: string;
            convocInfo: string;
        }
    }
    createdFromOtherForm: boolean;
    idFormOfModel: number;
    suggestionFormModelLoading: boolean;
    dateFinDiffer: Date;
}

let compteur = 0
let today
const todays = new Date();
const todayString: string = todays.getFullYear() + "-" + ("0" + (todays.getMonth() + 1)).slice(-2) + "-" + ("0" + todays.getDate()).slice(-2);
@observer
class ActionWorkflow extends React.Component<RouteComponentProps & IActionWorkflowProps, IActionWorkflowState> {
    public constructor(props: any) {
        super(props);
        this.state = {
            openAction: null,
            messageMotifRefus: "",
            messageMotifRefusForm: "",
            messageMotifReturn: "",
            refusGeneralMail: true,
            openModalHistoric: false,
            newTaskInserted: "",
            deleteModal: false,
            userAlreadyOnStep: [],
            suggestionInvitation: [ // Si un jour il y a une nouvelle valeur faire attention  aplus bas dans le code !!!!!
                { value: 1, label: "Contribution" },
                { value: 2, label: "Lecture" },
                { value: 3, label: "Avis non bloquant" }
            ],
            messageMotifReorientation: "",
            loading: "",
            idModelForReorientation: null,
            choiceType: null,
            model: [],
            displayMode: "list",
            userConnectedIsInGroup: false,
            userIdModif: null,
            formName: "",
            deleteDialogWFParams: {
                hideDialog: true,
                loading: false,
                error: false,
                restartWF: false,
                deleteAll: false
            },
            selectedWFToDelete: null,
            createFormModal: false,
            convocationModal: {
                open: false,
                convocationsFiles: [],
                loading: true,
                error: false,
                errorMessage: "",
                convocationsFilesAdded: [],
                fileConvocationName: "",
                fileConvocation: null,
                loadingAddButton: false,
                convocationData: {
                    convocDate: null,
                    convocPlace: "",
                    convocLink: "",
                    convocInfo: ""
                }
            },
            suggestionFormModel: [],
            createdFromOtherForm: false,
            idFormOfModel: null,
            suggestionFormModelLoading: true,
            dateFinDiffer: null
        }

        this.getModelById();
        this.getAllFirstClassModel();
        Store.delegationTask.originalRespOfTask = Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.userIdx ? Store.myStepTaskSelectionned.userIdx : null
    }

    componentDidUpdate(prevProps: IActionWorkflowProps, prevState: IActionWorkflowState) {
        if (prevProps.contextualMenuOpen == "extended" && this.props.contextualMenuOpen != "extended" && this.state.openAction != null) {
            this.onCloseAction();
        }
        if (this.props.myStepTaskSelectionned != undefined && prevProps.myStepTaskSelectionned != undefined && JSON.stringify(prevProps.myStepTaskSelectionned) != JSON.stringify(this.props.myStepTaskSelectionned)) {
            const userAl = this.props.myStepTaskSelectionned.WfStep.WfTasks.map(task => task.userIdx);
            this.setState({ userIdModif: this.props.myStepTaskSelectionned.userIdx, userAlreadyOnStep: userAl });
        }
    }

    public formatDate = (date) => {
        if ((date.getMonth() + 1) < 10) {
            return date.getFullYear() + "-0" + (date.getMonth() + 1) + "-" + date.getDate();
        } else {
            return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        }
    }

    public deleteWF = (idWf: number, restartWF: boolean, deleteAll: boolean) => (event: any) => {
        this.setState({ deleteDialogWFParams: { ...this.state.deleteDialogWFParams, loading: true } }, async () => {
            try {
                const resultDelete = await axios.post(Store.wsPath + "/1/workflows/deleteWf", { idWF: idWf, restartWF:restartWF, deleteAll:deleteAll });
                // const allFormList: IFormInfo[] = [...this.state.allForms].filter(frm => frm.id != form.id);

                Store.snackBar = {
                    message: "Circuit supprimé avec succès, redirection sur votre prochaine tâche",
                    error: false,
                    open: true
                };
                const query = useQuery();
                const b = query.get("b");
                if (b != undefined && b != null && b.length > 0) {
                    this.props.history.push("/refresh?redir=document?b=" + b);
                }
                else {
                    this.props.history.push("/refresh?redir=document");
                }
            } catch (error) {
                console.error("Error 'deleteWF' : ", error);
                this.setState({ deleteDialogWFParams: { ...this.state.deleteDialogWFParams, loading: false, error: true } });
                Store.snackBar = {
                    message: "Une erreur est survenue lors de la suppression de la demande",
                    error: true,
                    open: true
                };
            }
        });
    }
    public openCloseDeleteWFModal = (open: boolean, idWf?: number) => event => {
        if (open == true) {
            this.setState({ deleteDialogWFParams: { hideDialog: false, loading: false, error: false, restartWF: false, deleteAll: false }, selectedWFToDelete: idWf });
        }
        else {
            this.setState({ deleteDialogWFParams: { hideDialog: true, loading: false, error: false, restartWF: false, deleteAll: false } });
        }
    }

    setTimerDaberUnicorn = () => {
        Store.licorne = true
        setTimeout(() => {
            Store.licorne = false
        }, 2000);
    }

    convertDate = (cell) => {
        if (cell != null) {
            today = this.formatDateDisaplyingBootstrap(cell)
        } else {
            today = ""
        }
        return today
    }

    onCloseAction = () => {
        this.setState({ openAction: null, messageMotifRefus: "", messageMotifReturn: "", messageMotifReorientation: "", dateFinDiffer: null })
    }

    onCloseModalHistoric = () => {
        this.setState({ openModalHistoric: false })
    }

    public convertUser = (userIdx) => {
        const tempUserFind = Store.allUsers.find(user =>
            user.id == userIdx
        )
        if (tempUserFind != undefined || null) {
            return tempUserFind.firstname + ' ' + tempUserFind.lastname
        }
        else {
            return "Auteur introuvable"
        }
    }

    public formatDateDisaplyingBootstrap = (date) => {
        const dateConverted = new Date(date)
        if ((dateConverted.getMonth() + 1) < 10) {
            return dateConverted.getDate() + "/0" + (dateConverted.getMonth() + 1) + "/" + dateConverted.getFullYear() + " à " + dateConverted.getHours() + " h " + dateConverted.getMinutes()
        } else {
            return dateConverted.getDate() + "/" + (dateConverted.getMonth() + 1) + "/" + dateConverted.getFullYear() + " à " + dateConverted.getHours() + " h " + dateConverted.getMinutes()
        }
    }


    public getModelById = () => {
        if (Store.workflow.idModel != undefined && Store.workflow.idModel != null) {
            axios.get(Store.wsPath + '/1/workflows/getModelWfById', { params: { idModel: Store.workflow.idModel } })
                .then((response) => {
                    Store.modelWf = response.data
                })
        }
    }

    public getFormModelByType = () => {
        this.setState({ suggestionFormModelLoading: true }, () => {
            const formType: string = Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned != null && Store.myStepTaskSelectionned.WfStep.createFormType != undefined && Store.myStepTaskSelectionned.WfStep.createFormType != null ? Store.myStepTaskSelectionned.WfStep.createFormType : "lyform";
            axios.get(Store.wsPath + `/1/formulaire/GetAllFormsForAType`, {
                params: {
                    typeForm: formType
                }
            }).then((response) => {
                const suggestionFormModel = []
                response.data.forEach(f => {
                    suggestionFormModel.push({ value: f.id, label: f.name })
                });
                this.setState({ suggestionFormModel: suggestionFormModel, suggestionFormModelLoading: false })
            });
        });
    }

    // Fonction pour redirect sur le premier projet ou j'ai un tache en cours
    public redirectOnproject = (projectInSlider: any[]) => {
        if (projectInSlider.length > 0) {
            const taskInNotMyDoc = projectInSlider.filter(project => project.id != Store.workflow.id);
            if (taskInNotMyDoc.length > 0) {
                Store.reload = {
                    form: true,
                    banette: true
                };
                this.props.history.push("/document?w=" + taskInNotMyDoc[0].id);
            }
            else {
                this.props.history.push("/");
            }
        }
        else {
            this.props.history.push("/");
        }
    }

    // Fonction qui appelle un WS pour savoir si on doit changer de WF ou pas
    public checkedStayingWorkflow = (stepId: number, idUser: number): Promise<boolean> => {
        return new Promise(async (resolve, reject) => {
            try {
                if (stepId != undefined && stepId != null && idUser != undefined && idUser != null) {
                    const responseChecked = await axios.post(Store.wsPath + '/1/workflows/checkedStayingWorkflow', {
                        stepId: Store.myStepTaskSelectionned.WfStep.id,
                        idUser: Store.userConnected.id
                    });
                    resolve(responseChecked.data);
                }
                else {
                    throw new Error("paramétre stepId ou idUser manquant");
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    // Fonction appelée lors de la cloture d'une tâche
    public acceptTask = () => { // note pour le spinner 
        this.setState({ loading: "Accepter" }, () => {
            const documentId = Store.selectedDoc.id;
            const dateDone: Date = new Date();

            // Préparation des values à envoyer au back
            const formData = new FormData();

            // Travail sur les documents de convocations si il y a
            const customConvocFiles = {};
            if (this.state.convocationModal.convocationsFiles.length > 0 && this.state.convocationModal.convocationsFiles.filter(fi => fi.add == true).length > 0) {
                this.state.convocationModal.convocationsFiles.filter(fi => fi.add == true).forEach((file, index) => {
                    customConvocFiles["fileAdded" + file.name] = file.buffer;
                    formData.append("fileAdded" + index, file.buffer);
                });
            }

            // Préparation des données pour le back
            formData.append("stepId", Store.myStepTaskSelectionned.WfStep.id.toString());
            formData.append("taskId", Store.myStepTaskSelectionned.id.toString());
            formData.append("dateDone", dateDone.toString());
            formData.append("userConnected", JSON.stringify(Store.userConnected));
            formData.append("modeSharePoint", Store.modeSharepoint.toString());
            formData.append("documentId", documentId.toString());
            formData.append("isGRC", Store.selectedDoc.isGRC.toString());
            formData.append("isForm", Store.selectedDoc.isForm.toString());
            formData.append("formType", Store.selectedDoc.formType);
            formData.append("formName", this.state.formName);
            formData.append("convocationsFiles", JSON.stringify(this.state.convocationModal.convocationsFiles.filter(file => file.add != true)));
            formData.append("convocationData", JSON.stringify(this.state.convocationModal.convocationData));
            formData.append("isFormCreatedFromOtherForm", this.state.createdFromOtherForm.toString());
            if (this.state.idFormOfModel != null && this.state.idFormOfModel != undefined) {
                formData.append("idFormModel", this.state.idFormOfModel.toString());
            }

            axios.post(Store.wsPath + '/1/workflows/FinishTaskRole',
                formData,
                {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
            ).then((resp) => {
                this.resetLoading();
                if (Store.myStepTaskSelectionned.WfStep.isCreateForm == true) {
                    Store.snackBar = {
                        error: false,
                        message: "Votre formulaire a bien été créé",
                        open: true
                    }
                }
                const query = useQuery();
                const b = query.get("b");
                if (resp.data.stayOnSameProject == true) {
                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id + "&b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
                    }

                } else {

                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document");
                    }
                }

            }).catch(error => {
                console.error("Error 'FinishTaskRole' : ", error);
                this.resetLoading();
                Store.snackBar = {
                    error: true,
                    message: "Une erreur est survenue lors de la cloture de la tâche",
                    open: true
                }
            })
        });
    }

    // Fonction appelée lors de la modification de la tâche
    public editTaskName = () => {
        if (Store.myStepTaskSelectionned != undefined) {
            this.setState({ loading: "editTaskName" }, () => {
                const _id = Store.myStepTaskSelectionned.id;
                axios.post(Store.wsPath + '/1/workflows/updateTask', {
                    idTask: _id,
                    nameTask: Store.myStepTaskSelectionned.name,
                    idUser: this.state.userIdModif,
                    idDoc: Store.selectedDoc.id,
                    modeSharepoint: Store.modeSharepoint,
                    idUserConnected: Store.userConnected.id,
                }).then((response) => {
                    this.openContextualMenu('textfield');
                    Store.reload.form = true;
                    Store.contextualmenu.open = "open";
                    Store.myStepTaskSelectionned = undefined;
                    this.props.history.push("/document?w=" + Store.workflow.id);
                    this.resetLoading();
                    Store.snackBar = {
                        error: false,
                        message: "La tâche à été modifiée",
                        open: true
                    }
                    // this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
                }).catch(error => {
                    console.error("Error 'updateTask' : ", error);
                    Store.snackBar = {
                        error: true,
                        message: "Une erreur est survenue lors de la modification de la tâche",
                        open: true
                    }
                    this.resetLoading();
                })
            })
        }
        else {
            Store.snackBar = {
                error: true,
                message: "Pas de tâche sélectionnée",
                open: true
            }
        }
    }


    public onChangeValue = name => event => {
        if (name == "nameTask") {
            if (Store.myStepTaskSelectionned != undefined) {
                Store.myStepTaskSelectionned.name = event.target.value
            }
        } else if (name == "dateFinDiffer") {
            this.setState({
                dateFinDiffer: event.target.value
            })
        }
        else if (name == "return") {
            this.setState({
                messageMotifReturn: event.target.value
            })
        } else if (name == "refus") {
            this.setState({
                messageMotifRefus: event.target.value // Le Store Store.messageMotifRefus ne peut pas etre utilisé  car je l'appelle dans post it
            })
        }
        else if (name == "refusAuteurForm") {
            this.setState({
                messageMotifRefusForm: event.target.value
            })
        }
        else if (name == "refusGeneralMail") {
            this.setState({
                refusGeneralMail: event.target.checked
            })
        }
        else if (name == "réorientation") {
            this.setState({
                messageMotifReorientation: event.target.value
            })
        }
        else if (name == "nameNewTaskInserted") {
            this.setState({
                newTaskInserted: event.target.value
            })
        }
        else if (name == "createForm") {
            this.setState({
                formName: event.target.value
            })
        }
    }

    public returnStepAndTask = () => {
        this.setState({ loading: "Return" }, () => {
            Store.messageMotifReturn = this.state.messageMotifReturn
            axios.post(Store.wsPath + '/1/workflows/returnStepBefore', {
                stepId: Store.myStepTaskSelectionned.WfStep.id,
                taskId: Store.myStepTaskSelectionned.id,
                taskName: Store.myStepTaskSelectionned.name,
                docId: Store.selectedDoc.id,
                userConnected: Store.userConnected.id,
                modeSharePoint: Store.modeSharepoint
            }).then(async (resp) => {
                this.addMessage("return");
                // this.checkedStayingWorkflow(Store.myStepTaskSelectionned.WfStep.id, Store.userConnected.id).then(responseChecked => {
                //     if (responseChecked != undefined && responseChecked != null && responseChecked == false) {
                //         this.redirectOnproject(Store.taskSliderTasks);
                //     }
                //     else {
                //         Store.reload = {
                //             form: true,
                //             banette: true
                //         };
                //         Store.myStepTaskSelectionned = undefined;
                //         this.props.history.push("/document?w=" + Store.workflow.id);
                //     }
                //     this.resetLoading();
                // }).catch(error => {
                //     console.error("Error 'checkedStayingWorkflow' : ", error);
                //     Store.reload = {
                //         form: true,
                //         banette: true
                //     };
                //     this.props.history.push("/document?w=" + Store.workflow.id);
                //     this.resetLoading();
                // })
                const query = useQuery();
                const b = query.get("b");
                if (resp.data.stayOnSameProject == true) {
                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id + "&b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
                    }

                } else {

                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document");
                    }
                }
            }).catch(error => {
                console.error("Error 'returnStepAndTask' : ", error);
                this.resetLoading();
                Store.snackBar = {
                    error: true,
                    message: "Une erreur est survenue lors du retour de la tâche",
                    open: true
                }
            });
        })
    }

    public denied = () => {
        this.setState({ loading: "denied" }, async () => {
            try {
                const responseDenied = await axios.post(Store.wsPath + '/1/workflows/denied', {
                    projectId: Store.workflow.id,
                    taskId: Store.myStepTaskSelectionned.id,
                    stepId: Store.myStepTaskSelectionned.WfStep.id,
                    idUserConnected: Store.userConnected.id,
                    modeSharepoint: Store.modeSharepoint,
                    deniedMotifForm: this.state.messageMotifRefusForm,
                    refusGeneralMail: this.state.refusGeneralMail,
                    deniedMotif: this.state.messageMotifRefus
                })

                Store.snackBar = {
                    error: false,
                    message: "Le dossier à bien été refusé",
                    open: true
                }

                this.onCloseAction();
                const query = useQuery();
                const b = query.get("b");
                if (responseDenied.data.stayOnSameProject == true) {
                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id + "&b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
                    }

                } else {

                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document");
                    }
                }

            } catch (error) {
                console.error("Error 'Denied' : ", error);
                Store.snackBar = {
                    error: true,
                    message: "Erreur lors du refus du dossier",
                    open: true
                }
            }
            this.resetLoading();
        });
    }


    // test réorientation
    public reorientationStep = () => {
        this.setState({ loading: "reorientationStep" }, () => {
            axios.post(Store.wsPath + '/1/workflows/reorientationStep', {
                stepId: Store.myStepTaskSelectionned.WfStep.id,
                idUserConnected: Store.userConnected.id,
                modeSharepoint: Store.modeSharepoint,
                idDocument: Store.workflow.id
            }).then(responseReorientation => {
                this.addMessage("réorientation");
                Store.snackBar = {
                    error: false,
                    message: "Étape réorientée avec succés",
                    open: true
                }

                this.onCloseAction();
                //this.redirectOnproject(Store.taskSliderTasks);
                this.resetLoading();
                const query = useQuery();
                const b = query.get("b");
                if (responseReorientation.data.stayOnSameProject == true) {
                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id + "&b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
                    }

                } else {

                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document");
                    }
                }

            }).catch(error => {
                console.error("Error 'reorientationStep' : ", error);
                Store.snackBar = {
                    error: true,
                    message: "Erreur lors de la réorientation de l'étape",
                    open: true
                }
                this.resetLoading();
            });
        });
    }
    // -------------------------------- BLOC RECUPERATION MODELES
    public getAllFirstClassModel = () => {
        axios.get(Store.wsPath + `/1/workflows/getAllModelWf`).then((response) => {
            axios.get(Store.wsPath + `/1/formulaire/GetAllFormType`).then(resType => {
                const allTypeModel = [{ modelType: "lyflow", model: [] }];
                resType.data.forEach(tm => {
                    allTypeModel.push({ modelType: tm.name, model: [] })

                });
                let modeles = response.data;
                const modelesFiltered = [];
                modeles.forEach(modele => {
                    if (modele.idParent == null && modele.isForm == false) {
                        allTypeModel.forEach(at => {
                            if (at.modelType == "lyflow") {
                                at.model.push(modele)
                            }
                        })
                    } else if (modele.idParent == null && modele.isForm == true) {
                        allTypeModel.forEach(at => {
                            if (at.modelType == modele.formType) {
                                at.model.push(modele)
                            }
                        })
                    }
                    // if (modele.idParent == null && modelType == "lyflow" && modele.isForm == false) {
                    //  modelesFiltered.push(modele)
                    // }else if(modele.idParent == null && modele.formType == modelType  && modele.isForm == true){
                    //   modelesFiltered.push(modele)
                    // }
                });

                this.setState({ model: allTypeModel })
            })

        }).catch(error => {
            console.error("Error 'getAllModelsWf' : ", error);
            // SnackBar
            Store.snackBar.message = "Une erreur est survenue lors de la récupération de la liste des modèles, réessayez";
            Store.snackBar.error = true;
            Store.snackBar.open = true;
        });
    }



    // -------------------------------------------------------------
    public reorientationWorkflow = () => {
        this.setState({ loading: "reorientationWorkflow" }, () => {
            axios.post(Store.wsPath + '/1/workflows/reorientationWorkflow', {
                idWorkflow: Store.workflow.id,
                modeSharepoint: Store.modeSharepoint,
                idUserConnected: Store.userConnected.id,
                idModel: this.state.idModelForReorientation
            }).then(responseReorientationWorkflow => {
                this.addMessage("reorientationWorkflow")
                Store.snackBar = {
                    error: false,
                    message: "Le circuit réorienté avec succés",
                    open: true
                }
                this.onCloseAction();
                // this.redirectOnproject(Store.taskSliderTasks);
                const query = useQuery();
                const b = query.get("b");
                if (responseReorientationWorkflow.data.stayOnSameProject == true) {
                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id + "&b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
                    }

                } else {

                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document");
                    }
                }
                this.resetLoading();
            }).catch(error => {
                console.error("Error 'reorientationWorkflow' : ", error);
                Store.snackBar = {
                    error: true,
                    message: "Erreur lors de la réorientation du circuit",
                    open: true
                }
                this.resetLoading()
            })
        })
    }

    public addMessage = (/*idxPostIt: number,*/ action: string) => { // ?
        let message = "";
        if (action == "réorientation") {
            message = "<b>Document réorienté</b> <br />Etape : " + Store.myStepTaskSelectionned.name + "<br/>Motif : " + this.state.messageMotifReorientation
        }
        else if (action == "reorientationWorkflow") {
            message = "<b>le circuit a été réorienté</b> <br /> Motif : " + this.state.messageMotifReorientation
        }
        else {
            message = "<b>Etape retournée</b> <br /> Etape : " + Store.myStepTaskSelectionned.name + "<br/>Motif : " + this.state.messageMotifReturn
        }

        Store.addMessagePostIt = { message: message, add: true };
        this.onCloseAction();
    }

    public updateReactSelect = (user: any) => {
        const myUser = Store.suggestionsUser.find(suggestion => suggestion.value == user);
        return myUser
    }

    public updateReactSelectMulti = (users: number[]) => {
        const tempArray: any[] = [];
        users.forEach(idUser => {
            const tempSuggestion = Store.suggestionsUser.find(suggestion => suggestion.value == idUser);
            tempArray.push(tempSuggestion);
        });

        return tempArray;
    }
    public onChangeFormModel = (option) => {
        this.setState({ idFormOfModel: option.value })
    }
    public onChange = (option, type: string) => {
        if (type == "newTask") {
            if (option != null && option.length > 0) {
                const tempArray = option.map(user => user.value);
                Store.addEditRole.idxUsers = tempArray;
            }
            else {
                Store.addEditRole.idxUsers = [];
            }
        } else if (type == "modif") {
            if (option != null) {
                this.setState({ userIdModif: option.value });
            }
            else {
                this.setState({ userIdModif: null });
            }
        } else if (type == "delegation") {
            if (option != null) {
                Store.delegationTask.idUser = option.value;
            }
            else {
                Store.delegationTask.idUser = null;
            }
        } else if (type == "circuit") {
            if (option != null) {
                Store.delegationTask.idCircuit = option.value
            } else {
                Store.delegationTask.idCircuit = null
            }
        } else if (type == "typeInvitation") {
            if (option != null) {
                this.setState({
                    choiceType: {
                        value: option.value,
                        label: option.label
                    }
                });
            } else {
                this.setState({
                    choiceType: null
                });
            }
        } /* else if ( type == "newModel") {
            if (option != null ){
                this.setState({
                    idModelForReorientation : option.value
                })
            } else {
                this.setState({idModelForReorientation : null})
            }
        } */
    }

    public insertTaskInStep = () => {
        this.setState({ loading: "insertTaskInStep" }, async () => {
            try {
                const _idTask = Store.myStepTaskSelectionned.id
                const _idUsers = Store.addEditRole.idxUsers
                const _newTaskInserted = Store.myStepTaskSelectionned.WfStep.name; //this.state.newTaskInserted

                // Variable pour mettre si c'est une anb ou non
                let anb = false;
                let typeTask = this.state.choiceType;

                if (this.state.choiceType != null && this.state.choiceType.label == "Avis non bloquant") {
                    anb = true;
                    typeTask = this.state.suggestionInvitation.filter(type => type.label == "Lecture")[0];
                }

                const response = await axios.post(Store.wsPath + "/1/workflows/insertMultipleTaskInStep", {
                    idTask: _idTask,
                    idUsers: _idUsers,
                    newTaskInserted: _newTaskInserted,
                    idDoc: Store.selectedDoc.id,
                    typeTask: typeTask,
                    anb: anb,
                    modeSharepoint: Store.modeSharepoint,
                    isGRC: Store.selectedDoc.isGRC,
                    isForm: Store.selectedDoc.isForm,
                    formType: Store.selectedDoc.formType,
                    idUserConnected: Store.userConnected.id
                })

                if (response.status == 200) {
                    Store.reload = {
                        form: true,
                        banette: true
                    }
                    this.props.history.push("/document?w=" + Store.workflow.id);
                    Store.snackBar = {
                        error: false,
                        message: "La tâche à bien été ajoutée",
                        open: true
                    }

                    Store.contextualmenu.open = "open";
                    this.openContextualMenu('person');
                    // Reset des valeurs saisies dans les champs
                    this.setState({ newTaskInserted: "", choiceType: null })
                    Store.addEditRole.idxUsers = null;
                }
                else {
                    throw new Error("statut de la réponse incorrect");
                }
            } catch (error) {
                console.error("Error 'insertTaskInStep' : ", error);
                Store.snackBar = {
                    error: true,
                    message: "Erreur lors de l'ajout de la tâche",
                    open: true
                }
            }
            this.resetLoading();
        });
    }

    public onDeleteStepById = async () => {
        //   await this.checkedStayingWorkflow()
        const _idStep = Store.myStepTaskSelectionned.WfStep.id
        axios.post(Store.wsPath + "/1/workflows/onDeleteStepById", {
            idStep: _idStep,
            idUser: Store.userConnected.id,
            idDoc: Store.selectedDoc.id
        }).then(responseDelete => {
            if (responseDelete.data == "Etape supprimée") {
                Store.contextualmenu.content = "";
                Store.contextualmenu.open = "hidden";
                Store.reloadWorkFlow = true;
            } else {
                Store.snackBar.open = true
                Store.snackBar.message = "Impossible de supprimer cette étape"
                Store.snackBar.error = true
            }
        });
    }

    public onDeleteTaskById = async () => {
        this.setState({ loading: "deleteTaskProject" }, async () => {
            try {
                const _idTask = Store.myStepTaskSelectionned.id;
                const dateDoneConvertTodb = this.formatDate(new Date());
                const responseDelete = await axios.post(Store.wsPath + "/1/workflows/deleteTaskProject", {
                    id: _idTask,
                    idUserConnected: Store.userConnected.id,
                    idDoc: Store.selectedDoc.id,
                    dateDone: dateDoneConvertTodb,
                    modeSharePoint: Store.modeSharepoint,
                    isGRC: Store.selectedDoc.isGRC,
                    isForm: Store.selectedDoc.isForm,
                    formType: Store.selectedDoc.formType
                });

                if (responseDelete.status == 200) {
                    Store.snackBar = {
                        error: false,
                        message: "Tâche supprimée",
                        open: true
                    }
                    this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
                }
                else {
                    Store.snackBar = {
                        error: true,
                        message: "Une erreur est survenue lors de la suppression de la tâche",
                        open: true
                    }
                }
            } catch (error) {
                console.error("Error 'deleteTaskProject' : ", error);
                Store.snackBar = {
                    error: true,
                    message: "Une erreur est survenue lors de la suppression de la tâche",
                    open: true
                }
            }
            this.resetLoading();
            this.toggleModalDeleteTask();
        })
    }

    toggleModalDeleteTask = () => {
        this.setState({ deleteModal: !this.state.deleteModal });
    }
    toggleModalCreateForm = () => {
        this.setState({ createFormModal: !this.state.createFormModal, createdFromOtherForm: false, idFormOfModel: undefined });
    }

    toggleModalConvocation = (open?: boolean) => event => {
        if (open == true) {
            this.setState({ convocationModal: { ...this.state.convocationModal, loading: true, error: false, errorMessage: "", open: true, convocationsFiles: [] } }, async () => {
                let errorMessage = "";
                try {
                    const idForm = Store.workflow.Document.dataSharepoint.idForm;
                    if (idForm != undefined && idForm != null) {
                        errorMessage = "Une erreur est survenue lors de la récupération des convocations";
                        // On récupéres les convocations liais au formulaire
                        const responseConvocations = await axios.get(Store.wsPath + '/1/formulaire/GetConvocationByIdForm', {
                            params: {
                                idForm: idForm
                            }
                        });

                        this.setState({ convocationModal: { ...this.state.convocationModal, loading: false, convocationsFiles: responseConvocations.data } });
                    }
                    else {
                        errorMessage = "Formulaire non trouvé";
                        throw new Error("Formulaire non trouvé");
                    }
                } catch (error) {
                    console.error("Error 'GetConvocationByIdForm' : ", error);
                    this.setState({ convocationModal: { ...this.state.convocationModal, error: true, errorMessage: errorMessage, loading: false } });
                }
            });
        }
        else {
            this.setState({ convocationModal: { ...this.state.convocationModal, open: false, convocationsFiles: [] } });
        }
    }

    public openHistoricAction = (e) => {
        this.setState({ openModalHistoric: true })
        Store.historicAction.openModalHistoric = true
    }
    public openContextualMenu(action: string) {
        if (action == "person" || action == "editTask") {
            Store.contextualmenu.open = "extended";
        }

        if (action != undefined && action != null && action != this.state.openAction) {
            this.setState({ openAction: action });
        }
        else {
            this.setState({ openAction: null });
        }
    }




    public handleDateChange = typeDate => e => {
        if (typeDate == "dateBegin") {
            const dateBeginFormat = new Date(e.target.value)
            Store.delegationTask.dateBeginToString = e.target.value
            Store.delegationTask.dateBegin = new Date(dateBeginFormat.toDateString())
        } else if (typeDate == "dateEnd") {
            const dateEndFormat = new Date(e.target.value)
            Store.delegationTask.dateEndToString = e.target.value
            Store.delegationTask.dateEnd = new Date(dateEndFormat.toDateString())
            const calculTomorrow = Store.delegationTask.dateEnd.getDate() + 1
            Store.delegationTask.dateResetUserOriginal = new Date(Store.delegationTask.dateEnd.setDate(calculTomorrow))
            let dayFormat
            let monthFormat
            if (Store.delegationTask.dateResetUserOriginal.getDate() < 10) {
                dayFormat = "0" + Store.delegationTask.dateResetUserOriginal.getDate()
            } else {
                dayFormat = Store.delegationTask.dateResetUserOriginal.getDate()
            }
            if (Store.delegationTask.dateResetUserOriginal.getMonth() + 1 < 10) {
                monthFormat = "0" + (Store.delegationTask.dateResetUserOriginal.getMonth() + 1)
            } else {
                monthFormat = Store.delegationTask.dateResetUserOriginal.getMonth() + 1
            }
            Store.delegationTask.dateResetUserOriginalToString = Store.delegationTask.dateResetUserOriginal.getFullYear() + '-' + monthFormat + '-' + dayFormat
        }
    }

    public differTask = () => {
        try {
            axios.post(Store.wsPath + "/1/workflows/differTask", {
                task: Store.myStepTaskSelectionned,
                idUserConnected: Store.userConnected.id,
                idDoc: Store.workflow.Document.id,
                isDifferedEnd: this.state.dateFinDiffer
            }).then((response) => {
                const query = useQuery();
                const b = query.get("b");
                if (response.data.stayOnSameProject == true) {
                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id + "&b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
                    }

                } else {

                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?b=" + b);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document");
                    }
                }
            });
        } catch (error) {
            console.error("error : " + error)
            Store.snackBar.message = "Une erreur s'est produite, réessayez"
            Store.snackBar.open = true;
            Store.snackBar.error = true;
        }
    }
    public stopDifferTask = async () => {
        this.setState({ loading: "stopDiffere" }, async () => {
            try {
                axios.post(Store.wsPath + "/1/workflows/stopDifferTask", {
                    task: Store.myStepTaskSelectionned,
                    idUserConnected: Store.userConnected.id,
                    idDoc: Store.workflow.Document.id
                }).then((response) => {
                    const query = useQuery();
                    const b = query.get("b");
                    if (b != undefined && b != null && b.length > 0) {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id + "&b=" + b + "&s=" + Store.myStepTaskSelectionned.WfStep.id + "&t=" + Store.myStepTaskSelectionned.id);
                    }
                    else {
                        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
                    }
                });
            } catch (error) {
                console.error("error : " + error)
                Store.snackBar.message = "Une erreur s'est produite, réessayez"
                Store.snackBar.open = true;
                Store.snackBar.error = true;
            }
            this.resetLoading();
        })
    }

    public updateReactSelectInvit = (choice: any) => {
        const myValue = this.state.suggestionInvitation.find(suggestion => suggestion.value == choice.value);
        return myValue
    }

    public goToHistoricAction = () => {
        this.props.history.push("/HistoricActions/" + Store.selectedDoc.id)
    }

    public resetLoading = () => {
        this.setState({ loading: null });
    }

    public onClickChangeDisplayMode = (mode: string) => () => {
        this.setState({ displayMode: mode });
    }

    public onClickList = (id) => async (event) => {
        this.setState({ idModelForReorientation: id });
    }
    public approbStep = () => {
        axios.post(Store.wsPath + "/1/workflows/approbStep", {
            task: Store.myStepTaskSelectionned,
            user: Store.userConnected
        }).then((response) => {
            const query = useQuery();
            Store.myStepTaskSelectionned = undefined;
            this.props.history.push("/refresh?redir=document?" + query);
        }).catch(error => {
            console.error("approbStep : " + error)
            Store.snackBar.message = "Une erreur s'est produite, réessayez"
            Store.snackBar.open = true;
            Store.snackBar.error = true;
        });
    }

    public toolboxConvoc = (cell: string, row) => {
        let component = <React.Fragment></React.Fragment>
        component = (
            <React.Fragment>
                <span title="Supprimer le document"><Delete style={{ cursor: "pointer" }} onClick={this.deleteConvocFile(row.id)} /></span>
            </React.Fragment>
        )
        return (
            <div className={styles.toolboxContainer}>
                {component}
            </div>
        );
    }

    // Supprimer un document convocation de la liste des docs
    deleteConvocFile = (idConvoc: number) => event => {
        const convocations = [...this.state.convocationModal.convocationsFiles];
        const newConvoc = convocations.filter(conv => conv.id != idConvoc);
        this.setState({ convocationModal: { ...this.state.convocationModal, convocationsFiles: newConvoc } });
    }

    public formAlreadyHaveConvocationFileWhithSameName = (name) => {
        let alreadyExist = false;
        if (this.state.convocationModal.convocationsFiles != undefined && this.state.convocationModal.convocationsFiles.length != 0) {
            this.state.convocationModal.convocationsFiles.forEach(c => {
                if (c.name == name) {
                    alreadyExist = true;
                }
            });
        }
        return alreadyExist
    }

    public selectConvocFile = (event) => {
        let error = document.getElementById('error-fileConvocation');
        error.style.display = "none";
        if (this.formAlreadyHaveConvocationFileWhithSameName(event.target.files[0].name) == false) {
            this.setState({
                convocationModal: {
                    ...this.state.convocationModal,
                    fileConvocation: event.target.files[0],
                    fileConvocationName: event.target.files[0].name,
                }
            });
        } else {
            error.innerHTML = "Un document de convocation porte déjà le même nom, veuillez en sélectionner un nouveau";
            const input: any = document.getElementById("btn-uploadConvocation");
            if (input != undefined && input != null) {
                input.value = "";
            }
            error.style.display = "block";
        }
    };

    public validFileConvocation = (event) => {
        const allDocument = [...this.state.convocationModal.convocationsFiles];
        allDocument.push({ name: this.state.convocationModal.fileConvocationName, add: true, buffer: this.state.convocationModal.fileConvocation })
        this.setState({
            convocationModal: {
                ...this.state.convocationModal,
                convocationsFiles: allDocument,
                fileConvocation: null,
                fileConvocationName: "",
            }
        });
    };

    deleteSelectedConvocFile = () => {
        this.setState({
            convocationModal: {
                ...this.state.convocationModal,
                fileConvocation: undefined,
                fileConvocationName: ""
            }
        });
        const input: any = document.getElementById("btn-uploadConvocation");
        if (input != undefined && input != null) {
            input.value = "";
        }
    };

    public onChangeConvocFieldData = (fieldName) => (event) => {
        const valueState = { ...this.state.convocationModal.convocationData };
        if (fieldName == "convocDate") {
            valueState[fieldName] = event;
        }
        else {
            valueState[fieldName] = event.target.value;
        }
        this.setState({ convocationModal: { ...this.state.convocationModal, convocationData: valueState } });
    }

    formModelOnChange = event => {
        if (event.target.checked == true) {
            this.getFormModelByType();
            this.setState({ createdFromOtherForm: event.target.checked });
        } else {
            this.setState({ createdFromOtherForm: event.target.checked, idFormOfModel: null });
        }
    }

    onChangeDeleteWf = (type: string) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        const stateDeleteModalWF = { ...this.state.deleteDialogWFParams };
        if (type == "deleteAll") {
            stateDeleteModalWF.deleteAll = checked;
            if (checked) {
                stateDeleteModalWF.restartWF = false;
            }
        }
        else if (type == "restartWF") {
            stateDeleteModalWF.restartWF = checked;
            if (checked) {
                stateDeleteModalWF.deleteAll = false;
            }
        }
        this.setState({ deleteDialogWFParams: stateDeleteModalWF });
    }

    public render() {
        let isAloneInStep: boolean = false;

        if (Store.workflow.WfSteps != undefined && Store.workflow.WfSteps != null && Store.workflow.WfSteps.length > 0 && Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.WfStep.projetId == Store.workflow.id) {
            const find = Store.workflow.WfSteps.find(step => step.id === Store.myStepTaskSelectionned.WfStep.id);
            if (find != undefined) {
                isAloneInStep = find.WfTasks.length == 1;
            }
        }
        let classeContextualMenu = "";
        if (Store.contextualmenu.open == 'extended' || Store.contextualmenu.open == "open") {
            classeContextualMenu = styles.iconContent;
        }

        let userConnectedIsInGroup = false;
        if (Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.User.isGroup == true && Store.myStepTaskSelectionned.User.usersOfGroup != undefined && Store.myStepTaskSelectionned.User.usersOfGroup != null && Store.myStepTaskSelectionned.User.usersOfGroup.find(idUser => idUser == Store.userConnected.id) != undefined) {
            userConnectedIsInGroup = true
        }
        else {
            userConnectedIsInGroup = false
        }

        // Formatage du select typetask en fonction de la tache selectionnée
        let typeTaskOption = this.state.suggestionInvitation;
        // if (Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned != null && Store.myStepTaskSelectionned.typeTask != undefined && Store.myStepTaskSelectionned.typeTask != null) {
        //     typeTaskOption = this.state.suggestionInvitation.filter(sug => Store.myStepTaskSelectionned.typeTask.value <= sug.value);
        // }

        if (Store.userConnected.typeUserLydoc != "ged" && Store.userConnected.typeUserLydoc != "admin" && (Store.workflow.Document != undefined && Store.userConnected.id != Store.workflow.Document.userId)) {
            if (Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned != null && Store.myStepTaskSelectionned.typeTask != undefined && Store.myStepTaskSelectionned.typeTask != null) {
                typeTaskOption = this.state.suggestionInvitation.filter(sug => Store.myStepTaskSelectionned.typeTask.value <= sug.value);
            }
        }

        const show = {
            accept: false,
            differe: false,
            stopDiffere: false,
            return: false,
            inviteAndModif: false,
            denied: false,
            reoriente: false,
            changeModel: false,
            approp: false,
            deleteTask: false,
            historique: false,
            delete: false,
            response: false
        };

        if (Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.state == "En cours") {
            if (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin" || (Store.workflow.Document != undefined && Store.userConnected.id == Store.workflow.Document.userId)) {
                show.denied = true;
                if (Store.myStepTaskSelectionned.isDiffered != true) {
                    show.differe = true;
                } else {
                    show.stopDiffere = true;
                }
                if (!Store.myStepTaskSelectionned.WfStep.isStepOrientation) {
                    show.accept = true;
                } else {
                    show.accept = false;
                }
                if (Store.myStepTaskSelectionned.WfStep.isAllowApprop && (!isAloneInStep || Store.myStepTaskSelectionned.User.isGroup == true)) {
                    show.approp = true
                }
                if (Store.myStepTaskSelectionned.WfStep.idStepOrientation != undefined && Store.myStepTaskSelectionned.WfStep.idStepOrientation != null) {
                    show.reoriente = true;
                }
                if (Store.workflow.WfSteps.find(st => st.rankStep == Store.myStepTaskSelectionned.WfStep.rankStep - 1 && (st.isStepOrientation == true || st.isResponse == true || st.isSignature)) == undefined && Store.myStepTaskSelectionned.WfStep.rankStep && Store.myStepTaskSelectionned.WfStep.rankStep != 1) {
                    show.return = true
                }
            }
            else if (Store.userConnected.id == Store.myStepTaskSelectionned.userIdx || userConnectedIsInGroup) {
                if (Store.myStepTaskSelectionned.isDiffered != true) {
                    show.differe = true;
                } else {
                    show.stopDiffere = true;
                }
                if (!Store.myStepTaskSelectionned.WfStep.isStepOrientation) {
                    show.accept = true;
                }
                if (Store.myStepTaskSelectionned.WfStep.isAllowApprop && (!isAloneInStep || Store.myStepTaskSelectionned.User.isGroup == true)) {
                    show.approp = true
                }
                if (Store.myStepTaskSelectionned.anb != true) {
                    if (Store.workflow.WfSteps.find(st => st.rankStep == Store.myStepTaskSelectionned.WfStep.rankStep - 1 && st.isStepOrientation) == undefined && Store.myStepTaskSelectionned.WfStep.rankStep && Store.myStepTaskSelectionned.WfStep.rankStep != 1) {
                        show.return = true
                    }
                    if (Store.myStepTaskSelectionned.WfStep.idStepOrientation != undefined && Store.myStepTaskSelectionned.WfStep.idStepOrientation != null) {
                        show.reoriente = true;
                    }
                    if (Store.myStepTaskSelectionned.typeTask.label == "Contribution") {
                        show.denied = true;
                    }
                }
            }

            // if (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin" || (Store.workflow.Document != undefined && Store.userConnected.id == Store.workflow.Document.userId)) {
            //     show.denied = true;
            // }
            // else if (Store.userConnected.id == Store.myStepTaskSelectionned.userIdx || userConnectedIsInGroup) {
            //     if (Store.myStepTaskSelectionned.anb != true) {
            //         if (Store.myStepTaskSelectionned.typeTask.label == "Contribution") {
            //             show.denied = true;
            //         }
            //     }
            // }
        }
        if (Store.myStepTaskSelectionned != undefined && Store.workflow.state != "Refusé" && Store.workflow.state != "Terminé" && Store.myStepTaskSelectionned.state != "Terminée") {
            if (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin" || (Store.workflow.Document != undefined && Store.userConnected.id == Store.workflow.Document.userId)) {
                if (Store.myStepTaskSelectionned.WfStep.isRequired == true && Store.myStepTaskSelectionned.WfStep.WfTasks != undefined && Store.myStepTaskSelectionned.WfStep.WfTasks != null && Store.myStepTaskSelectionned.WfStep.WfTasks.length > 1) {
                    show.deleteTask = true;
                } else if (Store.myStepTaskSelectionned.WfStep.isRequired == false) { // Si elle ne l'est pas
                    show.deleteTask = true;
                }
                if (Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned != null && Store.myStepTaskSelectionned.anb != true) {
                    show.changeModel = true;
                    show.inviteAndModif = true;
                }
            }
            else if (Store.userConnected.id == Store.myStepTaskSelectionned.userIdx || userConnectedIsInGroup) {
                show.inviteAndModif = true;
                if (Store.myStepTaskSelectionned.typeTask && Store.myStepTaskSelectionned.typeTask.label == "Contribution") {
                    if (Store.myStepTaskSelectionned.WfStep.isRequired == true && Store.myStepTaskSelectionned.WfStep.WfTasks != undefined && Store.myStepTaskSelectionned.WfStep.WfTasks != null && Store.myStepTaskSelectionned.WfStep.WfTasks.length > 1) {
                        show.deleteTask = true;
                    } else if ( // Si elle l'est pas
                        Store.myStepTaskSelectionned.WfStep.isRequired == false
                    ) {
                        show.deleteTask = true;
                    }
                }
                if (Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned != null && Store.myStepTaskSelectionned.anb != true && Store.myStepTaskSelectionned.typeTask.label == "Contribution") {
                    show.changeModel = true;
                }
            }
        }
        if (Store.selectedDoc != undefined && Store.selectedDoc != null && Store.selectedDoc.id != undefined && Store.selectedDoc.id != null) {
            show.historique = true;
        }
        if (Store.selectedDoc != undefined && Store.selectedDoc != null && Store.selectedDoc.id != undefined && Store.selectedDoc.id != null && (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin")) {
            show.delete = true;
        }
        // Pour les step de signature (Pas sur de mon coup)
        if (Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.state == "En cours" && Store.myStepTaskSelectionned.WfStep.isSignature == true && Store.workflow.state != "Refusé" && Store.workflow.state != "Terminé") {
            show.accept = false;
            show.differe = false;
            show.reoriente = false;
            show.denied = false;
        }
        if (Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.state == "En cours" && Store.myStepTaskSelectionned.WfStep.isResponseStep == true && Store.workflow.state != "Refusé" && Store.workflow.state != "Terminé") {
            show.response = true;
        }

        return (
            <div className={styles.contentActions}>
                {show.accept || show.differe || show.denied || show.inviteAndModif || show.approp || show.reoriente || show.return ?
                    <span className={styles.separator}>Tâche</span>
                    :
                    ""
                }
                <div>
                    {show.accept ?
                        <div>
                            <div className={this.state.loading == "Accepter" ? classeContextualMenu + " " + styles.iconContentDisabled : classeContextualMenu} title="Accepter" onClick={this.state.loading == "Accepter" ? () => { } : Store.myStepTaskSelectionned.WfStep.isCreateForm == true ? this.toggleModalCreateForm : Store.myStepTaskSelectionned.WfStep.isConvocation == true ? this.toggleModalConvocation(true) : this.acceptTask}>
                                {this.state.loading == "Accepter" ?
                                    <Spinner className={styles.icon} size={24} />
                                    :
                                    <Icon className={styles.icon}>check</Icon>
                                }
                                Accepter
                            </div>
                            <div>
                                <Modal
                                    open={this.state.createFormModal}
                                    onClose={this.toggleModalCreateForm}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className={modal.modalFormModel}>
                                    <div className={modal.modalFormModelContent}>
                                        <Typography variant="h5" id="modal-title">
                                            Création d'un formulaire
                                        </Typography>
                                        <br />
                                        <TextField
                                            label="Nom du formulaire"
                                            onChange={this.onChangeValue("createForm")}
                                            multiline
                                            style={{ /* marginTop: "26px", */ width: "100%" }}
                                            color='primary'
                                            variant='outlined'
                                        />
                                        <FormControlLabel className={styles.formControlLabel} control={<Checkbox color='primary' onChange={this.formModelOnChange} />} label={"Créer à partir d'un modèle"} />
                                        {this.state.createdFromOtherForm == true ?
                                            this.state.suggestionFormModelLoading == false ?
                                                <SelectReact
                                                    options={this.state.suggestionFormModel}
                                                    onChange={option => this.onChangeFormModel(option)}
                                                    placeholder="Formulaire modèle"
                                                    filterOption={createFilter({ ignoreAccents: false })}
                                                    className={styles.field}
                                                    styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }) }}
                                                />
                                                : <Spinner size={24} />
                                            : <React.Fragment></React.Fragment>
                                        }
                                        <div className={modal.modalFormModelButton}>
                                            <Button
                                                onClick={this.toggleModalCreateForm}
                                                className={modal.button}
                                                variant="contained"
                                                color="secondary"
                                                disabled={this.state.loading == "Accepter" ? true : false}
                                            >
                                                Annuler
                                            </Button>
                                            <Button
                                                onClick={this.acceptTask}
                                                className={modal.button}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.loading == "Accepter" || this.state.formName.length == 0 || (this.state.createdFromOtherForm == true && this.state.idFormOfModel == undefined) ? true : false}
                                            >
                                                {
                                                    this.state.loading == "Accepter" ?
                                                        <Spinner size={24} />
                                                        :
                                                        "Valider"
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>

                                {/* Modal à ouvrir lors d'une step de convocation */}
                                <Modal
                                    open={this.state.convocationModal.open}
                                    onClose={this.toggleModalConvocation}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className={modal.modal}>
                                    <div className={modal.modalContent}>
                                        <Typography variant="h5">
                                            Convocation
                                        </Typography>
                                        <br />
                                        {
                                            this.state.convocationModal.error == true ?
                                                <p>{this.state.convocationModal.errorMessage}</p>
                                                :
                                                this.state.convocationModal.loading == true ?
                                                    <Spinner label="Chargement des convocations en cours..." labelPlacement="bottom" size={30} />
                                                    :
                                                    <div>
                                                        <div>
                                                            <label style={{ marginBottom: "0px !important" }} htmlFor="btn-uploadConvocation">
                                                                <input
                                                                    id="btn-uploadConvocation"
                                                                    name="btn-uploadConvocation"
                                                                    type="file"
                                                                    style={{ display: 'none' }}
                                                                    onChange={this.selectConvocFile}
                                                                />
                                                                {this.state.convocationModal.fileConvocationName != undefined && this.state.convocationModal.fileConvocationName != "" ?
                                                                    <React.Fragment></React.Fragment>
                                                                    :
                                                                    <Button component="span" variant="contained" color="primary"><Icon>add</Icon>Ajouter un document</Button>
                                                                }
                                                                <p style={{ margin: 0, padding: "10px 0", color: "orange" }} id="error-fileConvocation"></p>
                                                            </label>
                                                            {this.state.convocationModal.fileConvocationName != undefined && this.state.convocationModal.fileConvocationName != "" ?
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <p style={{ margin: "0" }}>{this.state.convocationModal.fileConvocationName}</p>
                                                                    <Button
                                                                        disabled={this.state.convocationModal.loadingAddButton}
                                                                        className={styles.btnConvoc}
                                                                        size="small"
                                                                        color="primary"
                                                                        variant="contained"
                                                                        onClick={this.validFileConvocation}
                                                                        style={{ marginLeft: "5px", marginRight: "5px" }}
                                                                        title="Ajouter le document"
                                                                    >

                                                                        {
                                                                            this.state.convocationModal.loadingAddButton === true ?
                                                                                <Spinner size={20} />
                                                                                : 'Ajouter'
                                                                        }
                                                                    </Button>

                                                                    <Button
                                                                        color="secondary"
                                                                        variant="contained"
                                                                        className={styles.btnConvoc}
                                                                        size="small"
                                                                        style={this.state.convocationModal.loadingAddButton === true ? { display: 'none' } : {}}
                                                                        onClick={this.deleteSelectedConvocFile}
                                                                    >
                                                                        Annuler
                                                                    </Button>
                                                                </div>
                                                                : <React.Fragment></React.Fragment>
                                                            }
                                                        </div>

                                                        <BootstrapTable
                                                            data={this.state.convocationModal.convocationsFiles}
                                                            keyField='uniqueIdString'
                                                            searchPlaceholder='Rechercher dans la liste'
                                                            search={true}
                                                            pagination
                                                            options={{
                                                                noDataText: "Pas de document de convocation pour ce formulaire",
                                                            }}
                                                            trClassName={styles.trBootstrapTable}
                                                        >
                                                            <TableHeaderColumn dataField='id' hidden >id</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='name' dataSort={true} >Nom du document</TableHeaderColumn>
                                                            <TableHeaderColumn width='50px' tdStyle={{ padding: 0, textAlign: "center" }} dataField='id' dataFormat={this.toolboxConvoc} ></TableHeaderColumn>
                                                        </BootstrapTable>

                                                        {/* Champs à remplir pour les infos essentielle à la candidature */}
                                                        <div>
                                                            <h4>Informations complémentaires envoi de la candidature :</h4>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                                                <DateTimePicker
                                                                    ampm={false}
                                                                    variant="dialog"
                                                                    inputVariant="outlined"
                                                                    format="dd/MM/yyyy HH:mm"
                                                                    margin="normal"
                                                                    value={this.state.convocationModal.convocationData.convocDate}
                                                                    onChange={this.onChangeConvocFieldData("convocDate")}
                                                                    style={{ margin: 0, width: "100%" }}
                                                                    showTodayButton
                                                                    todayLabel="Aujourd'hui"
                                                                    cancelLabel="Annuler"
                                                                    label="Date de convocation"
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                            <TextField
                                                                variant="outlined"
                                                                defaultValue={this.state.convocationModal.convocationData.convocPlace}
                                                                onChange={this.onChangeConvocFieldData("convocPlace")}
                                                                label="Lieu"
                                                                style={{ marginTop: "12px", width: "100%" }}
                                                            />
                                                            <TextField
                                                                variant="outlined"
                                                                defaultValue={this.state.convocationModal.convocationData.convocLink}
                                                                onChange={this.onChangeConvocFieldData("convocLink")}
                                                                label="Lien visio"
                                                                style={{ marginTop: "12px", width: "100%" }}
                                                            />
                                                            <TextField
                                                                variant="outlined"
                                                                defaultValue={this.state.convocationModal.convocationData.convocInfo}
                                                                onChange={this.onChangeConvocFieldData("convocInfo")}
                                                                multiline
                                                                minRows={3}
                                                                label="Info supplémentaire"
                                                                style={{ marginTop: "12px", width: "100%" }}
                                                            />
                                                        </div>

                                                        <div className={modal.modalButton}>
                                                            <Button
                                                                onClick={this.toggleModalConvocation(false)}
                                                                className={modal.button}
                                                                variant="contained"
                                                                color="secondary"
                                                                disabled={this.state.loading == "Accepter" ? true : false}
                                                            >
                                                                Annuler
                                                            </Button>
                                                            <Button
                                                                onClick={this.acceptTask}
                                                                className={modal.button}
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={this.state.loading == "Accepter" || this.state.convocationModal.convocationsFiles.length == 0 ? true : false}
                                                            >
                                                                {
                                                                    this.state.loading == "Accepter" ?
                                                                        <Spinner size={24} />
                                                                        :
                                                                        "Valider"
                                                                }
                                                            </Button>
                                                        </div>
                                                    </div>
                                        }

                                    </div>
                                </Modal>
                            </div>
                        </div>
                        : ""}

                    {show.differe ?
                        <div>
                            <div className={classeContextualMenu} title="Différer" onClick={() => { this.openContextualMenu('differ') }}><TimerIcon className={styles.icon} />Différer</div>
                            <div>
                                <Modal
                                    open={this.state.openAction == "differ" ? true : false}
                                    onClose={this.onCloseAction}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className={modal.modal}
                                >
                                    <div className={modal.modalContent}>
                                        <Typography variant="h5" id="modal-title">
                                            Différer votre tâche
                                        </Typography>
                                        <br />
                                        <p style={{ marginBottom: 0 }}>Attention : vous allez différer votre tâche, elle sera accessible dans la banette Différée.</p>
                                        <p style={{ marginTop: 0, marginBottom: 0 }}>Après avoir validé vous serez redirigé vers votre prochaine tâche.</p>
                                        <hr style={{ width: "50%" }}></hr>
                                        <p style={{ marginBottom: "10px", marginTop: "10px" }}>Optionnel: vous pouvez ajouter une date de fin.</p>

                                        <TextField
                                            variant='outlined'
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                            InputProps={{ inputProps: { min: todayString } }}
                                            onChange={this.onChangeValue("dateFinDiffer")}
                                        />
                                        <p style={{ marginTop: "10px" }} >Après cette date la tâche sera de retour dans votre banette en cours.</p>
                                        <hr style={{ width: "50%" }}></hr>
                                        <div className={modal.modalButton}>
                                            <Button
                                                onClick={this.onCloseAction}
                                                className={modal.button}
                                                variant="contained"
                                                color="secondary"
                                                disabled={this.state.loading == "differ" ? true : false}
                                            >
                                                Annuler
                                            </Button>
                                            <Button
                                                onClick={this.differTask}
                                                className={modal.button}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.loading == "differ" ? true : false}
                                            >
                                                {
                                                    this.state.loading == "differ" ?
                                                        <Spinner className={styles.icon} size={24} />
                                                        :
                                                        "Valider"
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        : ""}
                    {show.stopDiffere ?
                        <div>
                            <div className={classeContextualMenu} title="Repasser la tache en cours" onClick={() => { this.openContextualMenu('stopDiffere') }}><TimerOffIcon className={styles.icon} />Repasser en cours</div>
                            <div>
                                <Modal
                                    open={this.state.openAction == "stopDiffere" ? true : false}
                                    onClose={this.onCloseAction}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className={modal.modal}
                                >
                                    <div className={modal.modalContent}>
                                        <Typography variant="h5" id="modal-title">
                                            Repasser votre tâche en cours
                                        </Typography>
                                        <br />
                                        <p style={{ marginBottom: 0 }}>Attention : vous allez repasser votre tâche dans la banette En cours.</p>
                                        <div className={modal.modalButton}>
                                            <Button
                                                onClick={this.onCloseAction}
                                                className={modal.button}
                                                variant="contained"
                                                color="secondary"
                                                disabled={this.state.loading == "stopDiffere" ? true : false}
                                            >
                                                Annuler
                                            </Button>
                                            <Button
                                                onClick={this.stopDifferTask}
                                                className={modal.button}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.loading == "stopDiffere" ? true : false}
                                            >
                                                {
                                                    this.state.loading == "stopDiffere" ?
                                                        <Spinner className={styles.icon} size={24} />
                                                        :
                                                        "Valider"
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        : ""}
                    {show.approp ?
                        <div className={classeContextualMenu} title="Approprier" onClick={this.approbStep}><Icon className={styles.icon}>hail</Icon>Approprier</div>
                        : ""}
                    {show.return ?
                        <div>
                            <div className={classeContextualMenu} title="Retourner" onClick={() => { this.openContextualMenu('return') }}><Icon className={styles.icon} >subdirectory_arrow_left</Icon>Retourner</div>
                            <div>
                                <Modal
                                    open={this.state.openAction == "return" ? true : false}
                                    onClose={this.onCloseAction}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className={modal.modal}>
                                    <div className={modal.modalContent}>
                                        <Typography variant="h5" id="modal-title">
                                            Motif du retour
                                        </Typography>
                                        <br />
                                        <TextField
                                            label="Motif du retour"
                                            onChange={this.onChangeValue("return")}
                                            multiline
                                            style={{ /* marginTop: "26px", */ width: "100%" }}
                                            color='primary'
                                            variant='outlined'
                                        />
                                        <div className={modal.modalButton}>
                                            <Button
                                                onClick={this.onCloseAction}
                                                className={modal.button}
                                                variant="contained"
                                                color="secondary"
                                                disabled={this.state.loading == "Return" ? true : false}
                                            >
                                                Annuler
                                            </Button>
                                            <Button
                                                onClick={this.returnStepAndTask}
                                                className={modal.button}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.loading == "Return" || this.state.messageMotifReturn.length == 0 ? true : false}
                                            >
                                                {
                                                    this.state.loading == "Return" ?
                                                        <Spinner size={24} />
                                                        :
                                                        "Valider"
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        : ""}

                    {show.reoriente ?
                        <div>
                            <div className={classeContextualMenu} title={show.response == false ? "Réorienter" : "Changer la réponse"} onClick={() => { this.openContextualMenu('reorientationStep') }}><Icon className={styles.icon}>{show.response == false ? "sync" : "mail"}sync</Icon>{show.response == false ? "Réorienter" : "Changer la réponse"}</div>
                            <div>
                                <Modal
                                    open={this.state.openAction == "reorientationStep" ? true : false}
                                    onClose={this.onCloseAction}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className={modal.modal}>
                                    <div className={modal.modalContent}>
                                        <Typography variant="h5" id="modal-title">
                                            {show.response == false ? "Motif de la réorientation" : "Motif du changement de la réponse"}
                                        </Typography>
                                        <br />
                                        <TextField
                                            label={show.response == false ? "Motif de la réorientation" : "Motif du changement de la réponse"}
                                            onChange={this.onChangeValue("réorientation")}
                                            multiline
                                            style={{ /* marginTop: "26px", */ width: "100%" }}
                                            color='primary'
                                            variant='outlined'
                                        />
                                        <div className={styles.redWarning}>
                                            {show.response == false ? "Attention une réorientation supprime toutes les étapes et tâches de la précédente orientation." : "Attention un changement de réponse supprime toutes les étapes et tâches de la précédente réponse."}
                                        </div>
                                        <div className={modal.modalButton}>
                                            <Button
                                                onClick={this.onCloseAction}
                                                className={modal.button}
                                                variant="contained"
                                                color="secondary"
                                                disabled={this.state.loading == "reorientationStep" ? true : false}
                                            >
                                                Annuler
                                            </Button>
                                            <Button
                                                onClick={this.reorientationStep}
                                                className={modal.button}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.loading == "reorientationStep" || this.state.messageMotifReorientation.length == 0 ? true : false}
                                            >
                                                {
                                                    this.state.loading == "reorientationStep" ?
                                                        <Spinner className={styles.icon} size={24} />
                                                        :
                                                        "Valider"
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        : ""}

                    {show.denied ?
                        <div>
                            <div className={classeContextualMenu} title="Refuser" onClick={() => { this.openContextualMenu('refus') }}><Icon className={styles.icon}>backspace</Icon>Refuser</div>
                            <div>
                                <Modal
                                    open={this.state.openAction == "refus" ? true : false}
                                    onClose={this.onCloseAction}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className={modal.modal}>
                                    <div className={modal.modalContent}>
                                        <Typography variant="h5" id="modal-title">
                                            Motifs du refus
                                        </Typography>
                                        <br />
                                        <p style={{ marginBottom: 0 }}>Attention : vous allez refuser la totalité du circuit et pas uniquement votre tâche.</p>
                                        <p style={{ marginTop: 0 }}>Si vous souhaitez refuser uniquement votre tâche alors utilisez l'option Retourner</p>
                                        <TextField
                                            label="Motif du refus interne"
                                            onChange={this.onChangeValue("refus")}
                                            multiline
                                            style={{ /* marginTop: "26px", */ width: "100%" }}
                                            color='primary'
                                            variant='outlined'
                                        />
                                        {Store.workflow != undefined && Store.workflow != null && (Store.workflow.isGRC == true || Store.workflow.isForm == true) ?
                                            <TextField
                                                label="Modif du refus visible par l'auteur de la demande"
                                                onChange={this.onChangeValue("refusAuteurForm")}
                                                multiline
                                                style={{ margin: "20px 0 20px 0", width: "100%" }}
                                                color='primary'
                                                variant='outlined'
                                            />
                                            :
                                            ""
                                        }
                                        {Store.workflow != undefined && Store.workflow != null && (Store.workflow.formType != undefined && Store.workflow.isForm == true && Store.workflow.formType == "recruit") ?
                                            <FormControlLabel className={styles.formControlLabel} control={<Checkbox color='primary' onChange={this.onChangeValue("refusGeneralMail")} checked={this.state.refusGeneralMail} />} label={"Envoyer le mail de refus standard"} />
                                            :
                                            ""
                                        }
                                        <div className={modal.modalButton}>
                                            <Button
                                                onClick={this.onCloseAction}
                                                className={modal.button}
                                                variant="contained"
                                                color="secondary"
                                                disabled={this.state.loading == "denied" ? true : false}
                                            >
                                                Annuler
                                            </Button>
                                            <Button
                                                onClick={this.denied}
                                                className={modal.button}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.loading == "denied" || this.state.messageMotifRefus.length == 0 ? true : false}
                                            >
                                                {
                                                    this.state.loading == "denied" ?
                                                        <Spinner className={styles.icon} size={24} />
                                                        :
                                                        "Valider"
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        : ""}
                </div>
                <div>
                    {show.inviteAndModif ?
                        <div>
                            <div className={this.state.openAction == "person" ? classeContextualMenu + " " + styles.addPerson + " " + styles.activeBackgroundTitle : classeContextualMenu + " " + styles.addPerson} title="Inviter" onClick={() => { this.openContextualMenu('person') }}><Icon className={styles.icon}>person_add</Icon>Inviter</div>
                            {this.state.openAction == "person" ?
                                <div className={styles.editFieldContainer + " " + styles.activeBackground}>
                                    {/* <TextField
                                        label="Nom de la tâche"
                                        onChange={this.onChangeValue("nameNewTaskInserted")}
                                        value={this.state.newTaskInserted}
                                        color='primary'
                                        variant='outlined'
                                        className={styles.field}
                                    /> */}


                                    <SelectReact
                                        options={Store.suggestionsUser.filter(user => (this.state.userAlreadyOnStep.find(idx => idx == user.value) == undefined))}
                                        onChange={option => this.onChange(option ? option : null, 'newTask')}
                                        placeholder="Acteur(s) de la tâche"
                                        filterOption={createFilter({
                                            ignoreAccents: false
                                        })}
                                        isMulti
                                        value={Store.addEditRole.idxUsers != undefined ? this.updateReactSelectMulti(Store.addEditRole.idxUsers) : null}
                                        className={styles.field}
                                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }) }}
                                        isClearable
                                        components={{ SingleValue: customSingleValue, Option: MenuList }}

                                    />

                                    <SelectReact
                                        options={typeTaskOption}
                                        onChange={option => this.onChange(option ? option : null, 'typeInvitation')}
                                        placeholder="Type d'invitation"
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        value={(this.state.choiceType != undefined && this.state.choiceType.value != undefined) ? this.updateReactSelectInvit(this.state.choiceType) : null}
                                        className={styles.field}
                                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }) }}
                                        isClearable
                                    />

                                    <Button
                                        // VOIR EVENTUEL CONFLIT MERGE AVEC JOHN
                                        onClick={this.insertTaskInStep}
                                        className={styles.btnUser}
                                        color='primary'
                                        variant='contained'
                                        disabled={this.state.choiceType == null || this.state.loading == "insertTaskInStep" || (Store.addEditRole.idxUsers == null && Store.addEditRole.idxUsers.length > 0) ? true : false}
                                    >
                                        {
                                            this.state.loading == "insertTaskInStep" ?
                                                <Spinner className={styles.icon} size={24} />
                                                :
                                                "Valider"
                                        }
                                    </Button>
                                </div>
                                :
                                ""}
                            <div className={this.state.openAction == "editTask" ? styles.activeBackgroundTitle + " " + classeContextualMenu : classeContextualMenu} title="Modifier" onClick={() => { this.openContextualMenu('editTask') }}> <Icon className={styles.icon}>edit</Icon>Modifier</div>
                            {this.state.openAction == "editTask" ?
                                <div className={styles.editFieldContainer + " " + styles.activeBackground}>
                                    {/* <TextField
                                        label="Modifier la tâche"
                                        onChange={this.onChangeValue('nameTask')}
                                        color='primary'
                                        value={Store.myStepTaskSelectionned.name ? Store.myStepTaskSelectionned.name : ""}
                                        variant='outlined'
                                        className={styles.field}
                                    /> */}

                                    <SelectReact
                                        options={Store.suggestionsUser}
                                        onChange={option => this.onChange(option ? option : null, 'modif')}
                                        placeholder="Acteur de la tâche"
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        value={this.state.userIdModif != undefined ? this.updateReactSelect(this.state.userIdModif) : null}
                                        className={styles.field}
                                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                                        isClearable
                                        components={{ SingleValue: customSingleValue, Option: MenuList }}

                                    />

                                    <Button
                                        onClick={this.editTaskName}
                                        className={styles.btnUser}
                                        color='primary'
                                        variant="contained"
                                        disabled={this.state.loading == "editTaskName" || this.state.userIdModif == null ? true : false}
                                    >
                                        {this.state.loading == "editTaskName" ?
                                            <Spinner className={styles.icon} size={24} />
                                            :
                                            "Valider"
                                        }
                                    </Button>
                                </div>
                                :
                                ""
                            }
                            {show.deleteTask ?
                                <div>
                                    {/* <div className={classeContextualMenu} title="Supprimer l'étape" onClick={this.onDeleteStepById}> <Icon className={styles.icon}>delete_forever</Icon>Supprimer l'étape</div> */}
                                    <div className={classeContextualMenu} title="Supprimer la tâche" onClick={this.toggleModalDeleteTask}> <Icon className={styles.icon}>close</Icon>Supprimer la tâche</div>
                                    <Modal
                                        open={this.state.deleteModal}
                                        onClose={this.toggleModalDeleteTask}
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                        className={modal.modal}>
                                        <div className={modal.modalContent}>
                                            <Typography variant="h5" id="modal-title">
                                                Suppression d'une tâche
                                            </Typography>
                                            <br />
                                            Vous allez supprimer cette tâche,
                                            Êtes-vous sûr de cette action ?
                                            <div className={modal.modalButton}>
                                                <Button
                                                    onClick={this.toggleModalDeleteTask}
                                                    className={modal.button}
                                                    variant="contained"
                                                    color="secondary"
                                                    disabled={this.state.loading == "deleteTaskProject" ? true : false}
                                                >
                                                    Annuler
                                                </Button>
                                                <Button
                                                    onClick={this.onDeleteTaskById}
                                                    className={modal.button}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.state.loading == "deleteTaskProject" ? true : false}
                                                >
                                                    {
                                                        this.state.loading == "deleteTaskProject" ?
                                                            <Spinner className={styles.icon} size={24} />
                                                            :
                                                            "Valider"
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                :
                                ""}
                        </div>
                        : ""
                    }
                </div>
                <span className={styles.separator}>Circuit</span>
                {show.changeModel ?
                    <div>
                        <div className={classeContextualMenu} title="Changer de modèle" onClick={() => { this.openContextualMenu('reorientationWorkflow') }}><Icon className={styles.icon}>sync</Icon>Changer de modèle</div>
                        <div>
                            <Dialog
                                open={this.state.openAction == "reorientationWorkflow" ? true : false}
                                onClose={this.onCloseAction}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                className={modal.modal}>
                                <div className={modal.modalContent}>
                                    <DialogTitle id="modal-title">
                                        Motif de la réorientation
                                    </DialogTitle>
                                    <br />
                                    <TextField
                                        label="Motif du changement de type de circuit"
                                        onChange={this.onChangeValue("réorientation")}
                                        multiline
                                        style={{ /* marginTop: "26px", */ width: "100%", textAlign: "center" }}
                                        color='primary'
                                        variant='outlined'
                                    />
                                    <div className={styles.redWarning}>
                                        Attention un changement de modèle de circuit supprimera toutes les étapes du précédent modèle
                                    </div>
                                    <div className={styles.formControlModelSelect}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ alignItems: "center", display: "flex" }}>Affichage :</div>
                                            <IconButton disableRipple style={{ padding: "0 4px 0 0 !important", color: this.state.displayMode == "grid" ? "black" : "grey" }} aria-label="Grille" onClick={this.onClickChangeDisplayMode("grid")}>
                                                <AppsIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton disableRipple style={{ padding: "0 !important", color: this.state.displayMode == "list" ? "black" : "grey" }} aria-label="Liste" onClick={this.onClickChangeDisplayMode("list")}>
                                                <ListIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                        {
                                            this.state.displayMode == "grid" ?
                                                <Grid container className={styles.gridContainer}>
                                                    {Store.workflow.isForm == false && this.state.model.filter(m => m.modelType == "lyflow").length > 0 ?
                                                        this.state.model.filter(m => m.modelType == "lyflow")[0].model.map((model, index) => {
                                                            const id = model.id
                                                            let cssSelectionned: string = ""
                                                            if (id == this.state.idModelForReorientation) {
                                                                cssSelectionned = "selected"
                                                            }
                                                            return (
                                                                <Grid key={model.icon != null && model.icon != undefined ? model.icon.label + index : "icon" + index} item lg={3} sm={3} xs={5} className={styles.cardsContainer}>
                                                                    <Card title={model.name} onClick={this.onClickList(model.id)}>
                                                                        <CardContent className={styles.modelOrientation + " " + cssSelectionned}>{model.name}</CardContent>
                                                                        <div className={styles.iconCards}><Icon fontSize="large"  >{model.icon != null && model.icon != undefined ? model.icon.label : ""}</Icon></div>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })
                                                        : Store.workflow.isForm == true && this.state.model.filter(m => m.modelType == Store.workflow.formType).length > 0 ?
                                                            this.state.model.filter(m => m.modelType == Store.workflow.formType)[0].model.map((model, index) => {
                                                                const id = model.id
                                                                let cssSelectionned: string = ""
                                                                if (id == this.state.idModelForReorientation) {
                                                                    cssSelectionned = "selected"
                                                                }
                                                                return (
                                                                    <Grid key={model.icon != null && model.icon != undefined ? model.icon.label + index : "icon" + index} item lg={3} sm={3} xs={5} className={styles.cardsContainer}>
                                                                        <Card title={model.name} onClick={this.onClickList(model.id)}>
                                                                            <CardContent className={styles.modelOrientation + " " + cssSelectionned}>{model.name}</CardContent>
                                                                            <div className={styles.iconCards}><Icon fontSize="large"  >{model.icon != null && model.icon != undefined ? model.icon.label : ""}</Icon></div>
                                                                        </Card>
                                                                    </Grid>
                                                                )
                                                            })
                                                            : ""
                                                    }
                                                </Grid>
                                                : this.state.displayMode == "list" ?
                                                    <List dense={true}>
                                                        {Store.workflow.isForm == false && this.state.model.filter(m => m.modelType == "lyflow").length > 0 ?
                                                            this.state.model.filter(m => m.modelType == "lyflow")[0].model.map((model, index) => {
                                                                const id = model.id
                                                                let cssSelectionned: string = ""
                                                                if (id == this.state.idModelForReorientation) {
                                                                    cssSelectionned = "selected"
                                                                }
                                                                return (
                                                                    <ListItem style={{ cursor: "pointer" }} className={cssSelectionned}>
                                                                        <ListItemAvatar>
                                                                            <Avatar >
                                                                                <Icon>{model.icon.label}</Icon>
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            onClick={this.onClickList(model.id)}
                                                                            primary={model.name}
                                                                        />
                                                                    </ListItem>
                                                                )
                                                            })
                                                            : Store.workflow.isForm == true && this.state.model.filter(m => m.modelType == Store.workflow.formType).length > 0 ?
                                                                this.state.model.filter(m => m.modelType == Store.workflow.formType)[0].model.map((model, index) => {
                                                                    const id = model.id
                                                                    let cssSelectionned: string = ""
                                                                    if (id == this.state.idModelForReorientation) {
                                                                        cssSelectionned = "selected"
                                                                    }
                                                                    return (
                                                                        <ListItem style={{ cursor: "pointer" }} className={cssSelectionned}>
                                                                            <ListItemAvatar>
                                                                                <Avatar >
                                                                                    <Icon>{model.icon.label}</Icon>
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                onClick={this.onClickList(model.id)}
                                                                                primary={model.name}
                                                                            />
                                                                        </ListItem>
                                                                    )
                                                                })
                                                                : ""
                                                        }
                                                    </List>
                                                    : ""
                                        }
                                    </div>
                                    <div className={modal.modalButton}>
                                        <Button
                                            onClick={this.onCloseAction}
                                            className={modal.button}
                                            variant="contained"
                                            color="secondary"
                                            disabled={this.state.loading == "reorientationWorkflow" ? true : false}
                                        >
                                            Annuler
                                        </Button>
                                        <Button
                                            onClick={this.reorientationWorkflow}
                                            className={modal.button}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.loading == "reorientationWorkflow" || this.state.messageMotifReorientation.length == 0 ? true : false}
                                        >
                                            {
                                                this.state.loading == "reorientationWorkflow" ?
                                                    <Spinner className={styles.icon} size={24} />
                                                    :
                                                    "Valider"
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                    : ""
                }
                {show.delete ?
                    <div className={styles.menuHistorique}>
                        <div className={classeContextualMenu} title="Supprimer" onClick={this.openCloseDeleteWFModal(true, Store.workflow.id)}> <Icon className={styles.icon}>delete</Icon>Supprimer</div>
                        <DialogFabric
                            hidden={this.state.deleteDialogWFParams.hideDialog}
                            onDismiss={this.openCloseDeleteWFModal(false)}
                            dialogContentProps={{
                                type: DialogType.normal,
                                title: "Supprimer le circuit",
                                closeButtonAriaLabel: 'Fermer',
                            }}
                            modalProps={{
                                isBlocking: true
                            }}
                        >
                            {
                                this.state.deleteDialogWFParams.error == true ?
                                    <p>Une erreur est survenue lors de la suppression du circuit</p>
                                    :
                                    this.state.deleteDialogWFParams.loading == true ?
                                        <SpinnerFabric size={SpinnerSize.large} label="Suppression du circuit en cours" labelPosition="bottom" />
                                        :

                                        <React.Fragment>
                                            <p>Vous allez supprimer un circuit, toutes les données citées ci dessous seront supprimées :</p>
                                            <ul>
                                                <li>Circuit</li>
                                                <li>Tâches</li>
                                                <li>Documents joints</li>
                                                <li>Post-it</li>
                                            </ul>

                                            {
                                                Store.workflow != undefined && Store.workflow.Document != undefined && Store.workflow.Document != null && Store.workflow.Document.isForm != true ?
                                                <React.Fragment>
                                                    <Separator alignContent="start"><Label>Options</Label></Separator>
                                                    <div>
                                                        <CheckboxFluent label="Repasser le document en file d'attente de traitement" checked={this.state.deleteDialogWFParams.restartWF} onChange={this.onChangeDeleteWf("restartWF")} />
                                                        <CheckboxFluent label="Supprimer également les fichiers" checked={this.state.deleteDialogWFParams.deleteAll} onChange={this.onChangeDeleteWf("deleteAll")} />
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <React.Fragment />
                                            }
                                            <DialogFooter>
                                                <PrimaryButton onClick={this.deleteWF(this.state.selectedWFToDelete, this.state.deleteDialogWFParams.restartWF, this.state.deleteDialogWFParams.deleteAll)} text="Confirmer" />
                                                <DefaultButton onClick={this.openCloseDeleteWFModal(false)} text="Annuler" />
                                            </DialogFooter>
                                        </React.Fragment>
                            }
                        </DialogFabric>
                    </div>
                    :
                    ""
                }
                {show.historique ?
                    <div className={styles.menuHistorique}>

                        <div className={classeContextualMenu} title="Historique" onClick={this.openHistoricAction}> <Icon className={styles.icon}>history</Icon>Historique</div>
                        {Store.historicAction.openModalHistoric == true ?
                            <Dialog
                                maxWidth='xl'
                                open={this.state.openModalHistoric}
                                onClose={this.onCloseModalHistoric}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <DialogTitle id="modal-title">
                                    Historique des actions
                                </DialogTitle>
                                <DialogContent>
                                    <HistoricActions idDoc={Store.selectedDoc.id} />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={this.onCloseModalHistoric}
                                        className={modal.button}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Retour
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            :
                            ""}
                    </div>
                    :
                    ""
                }
            </div>
        )
    }
}
export default withRouter(ActionWorkflow);